import { useCallback, useEffect, useState } from "react";
import ApiServices from "../Services/ApiServices";
import { displayAlert } from "../Components/Utils/Appfunctions";
import "../Components/styles.css";
import PaystackButton from "../Components/Utils/Paystack";
import Navbar from "../Components/Navbar";
import { useNavigate } from "react-router-dom";


function Checkout() {

  const initialValues = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false)

   const [cart, setCart] = useState({})

  const [alertmessage, setAlertmessage] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState('');


  const payNow = useCallback( async (cart) => {
      setIsSubmitting(true)
    try {
      console.log("I dey work = 34", cart);
      const response = await ApiServices.postRequestNoAuth('/controllers/createinvoice.php', cart);
      if(response) {
        console.log("respo", response);
           const {success, authorization_url} = response;
            if(success){
               setPaymentUrl(authorization_url);
                // Redirect to payment URL
                console.log("I dey work", paymentUrl);
                window.location.href = authorization_url;
            }
           
      } else {
        setIsSubmitting(false)
        setAlertmessage(true)
        setMessage(message)
        setIsError(true)
        setTimeout(()=>setAlertmessage(false), 3000)
      }
    } catch (error) {
      console.error('Watchlist Removeal Error:', error);
    }

  }, []);

  useEffect(()=>{

    const getCart =()=> {
       var getter =  ApiServices.getLocalStorage('cart')
       setCart(getter)
       console.log("carter", getter);

    }
    getCart();

  }, [])

  return (

    <>
      <Navbar />
      <div className="container-fluid" style={{backgroundColor:"black"}}>
        {/* {displayAlert({ alertmessage, isError, message })} */}
        <div className="row">
          <div className="col-lg-3"></div>
          <div className="col-lg-6 vh-100">
            <br/><br/><br/>
            <div className="p-2 bg-light shadow mt-5 rounded-4">
                
              <div className="text-white ">

                <h4 className="m-3 p-2 text-dark">Booking Summary</h4>


                <table class="table table-borderless">
                      <thead>
                      </thead>
                      <tbody>

                        <tr>
                          <th scope="row"></th>
                          <td>
                            <span className="fw-bold fs-6"> Name </span>                
                          </td>
                          <td></td>
                          <td>       
                            <span className="ms-3 fs-6 ">{cart.fullname}</span>
                          </td>
                        </tr>

                        <tr>
                          <th scope="row"></th>
                          <td>
                            <span className="fw-bold fs-6"> Email </span>                
                          </td>
                          <td></td>
                          <td>       
                            <span className="ms-3 fs-6 ">{cart.email}</span>
                          </td>
                        </tr>

                        {cart.phonenumber && 
                        
                        <tr>
                          <th scope="row"></th>
                          <td>
                            <span className="fw-bold fs-6"> Phone </span>                
                          </td>
                          <td></td>
                          <td>       
                            <span className="ms-3 fs-6 ">{cart.phonenumber}</span>
                          </td>
                        </tr>
                        }
                        


                        <tr>
                          <th scope="row"></th>
                          <td>
                            <span className="fw-bold fs-6">Checkin </span>                
                          </td>
                          <td></td>
                          <td>       
                            <span className="ms-3 cartspan ">{cart.checkIn}</span>
                          </td>
                        </tr>

                        <tr>
                          <th scope="row"></th>
                          <td>
                            <span className="fw-bold fs-6"> Checkout </span>                
                          </td>
                          <td></td>
                          <td>       
                            <span className="ms-3 cartspan" >{cart.checkOut}</span>
                          </td>
                        </tr>

                        <tr>
                          <th scope="row"></th>
                          <td>
                            <span className="fw-bold fs-6"> Nights</span>                
                          </td>
                          <td></td>
                          <td>       
                            <span className="ms-3 fs-6 ">{cart.numberOfDays}</span>
                          </td>
                        </tr>

                        {cart.adults && 
                         <tr>
                          <th scope="row"></th>
                          <td>
                            <span className="fw-bold fs-6"> Adults </span>                
                          </td>
                          <td></td>
                          <td>       
                            <span className="ms-3 fs-6 ">{cart.adults}</span>
                          </td>
                        </tr>
                        }
                       
                       
                       {cart.children && 
                        <tr>
                          <th scope="row"></th>
                          <td>
                            <span className="fw-bold fs-6"> children </span>                
                          </td>
                          <td></td>
                          <td>       
                            <span className="ms-3 fs-6 ">{cart.children}</span>
                          </td>
                        </tr>
                       }
                       

                        <tr>
                          <th scope="row"></th>
                          <td>
                            <span className="fw-bold fs-6"> Amount Per day </span>                
                          </td>
                          <td></td>
                          <td>       
                            <span className="ms-3 fs-6 "> {cart.amountPerday }</span>
                          </td>
                        </tr>

                        <tr>
                          <th scope="row"></th>
                          <td>
                            <span className="fw-bold fs-6"> Total Amount </span>                
                          </td>
                          <td></td>
                          <td>       
                            <span className="ms-3 fs-6 ">{cart.amount}</span>
                          </td>
                        </tr>

                        <tr>
                          <th scope="row"></th>
                          <td>
                            <span className="fw-bold fs-6"> Invoice ID </span>                
                          </td>
                          <td></td>
                          <td>       
                            <span className="ms-3 cartspan">{cart.invoiceId}</span>
                          </td>
                        </tr>


                      </tbody>
                    </table>

                  <div className="text-center"> 
                     <button className="bg-dark text-light p-2 fs-5 "
                      disabled={isSubmitting}
                      onClick={()=>payNow(cart)}>
                       {isSubmitting? "processing" : "Pay Now"} 
                      </button>
                  </div>

              

              </div>
            </div>
          </div>
          <div className="col-lg-3"></div>
        </div>
      </div>
    </>
  );
}

export default Checkout;
