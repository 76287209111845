import {  useCallback, useEffect, useState } from "react";
import ApiServices from "../Services/ApiServices";

export function useBookingProfile(bookingId) {

   const [bookingInfo, setBookingInfo] = useState({})
    
   const [alertmessage, setAlertmessage] = useState(false)
   const [message, setMessage] = useState(" ")
   const [isError, setIsError] = useState(false)
  


  const handleCheckout = useCallback((bookingId)=>{
      // 
  },[])
 

    const fetchBookingInfo = async () => {

        try {
          
          const res = await ApiServices.getRequestNoAuth(`/controllers/getbookinginfo.php?bookingId=${bookingId.bookingId}`);
              console.log("userBooking", res?.bookings);
          // setAlertmessage(true)
               if (res.success){
                setBookingInfo(res?.bookings)
                console.log("myfdf", bookingInfo);
               }else{
                setBookingInfo({})
               }   
          
        } catch (error) {
          console.error('Message retrieval error:', error.response);
          // Display error message to user
        }
      };
    
    
      useEffect(() => {
        fetchBookingInfo();
        
      }, []);
    
 
      
    return { bookingInfo,handleCheckout };
}
