import { useEffect, useState } from "react";
import { useSpring, animated } from 'react-spring';


function AniamteWelcome() {
    const [toggle, setToggle] = useState(false);

    const props = useSpring({
    color: toggle ? "yellow" : 'black',
      config: { duration: 1000 },
      reset: true
    });
  
    useEffect(() => {
      const interval = setInterval(() => {
        setToggle(!toggle);
      }, 2000);
  
      return () => clearInterval(interval);
    }, [toggle]);
    return (   
        <animated.p style={props} >Welcome to AludysHomes</animated.p>
      );
}

export default AniamteWelcome;