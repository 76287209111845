import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import ApiServices from "../Services/ApiServices";

const VerifyPay = () => {
  const location = useLocation();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  console.log("location = ", location);



  useEffect(() => {

    const queryParams = new URLSearchParams(location.search);

    const reference = queryParams.get("reference");
    const invoiceId = queryParams.get("groupId");
    console.log("reference = ", reference);

    if (reference !== null) {

      setLoading(true);
      const verifyTransaction = async () => {
        
        try {
          const response = await ApiServices.getRequestNoAuth(
            `/controllers/createbooking.php?reference=${reference}`
          );
          console.log("verifyResponse", response);

          if (response) {

                const {success, message} = response;
                if (success){
                    setLoading(false);
                    setError(false);
                    setMessage(message);
                    setTimeout(() => navigate(`/reservations`), 3000);
                }else{
                  setLoading(false);
                  setError(true);
                  setMessage(message);
                  setTimeout(() => navigate(`/booking`), 2000);
                }
           
          } else {
            setLoading(false);
            setError(true);
            setMessage("Network Error");
            setTimeout(() => navigate(`/booking`), 10000);
          }
          // Redirect or perform any additional actions here
        } catch (error) {
          setMessage("Transaction verification failed. Please try again.");
          console.error("Transaction verification error:", error);
        }
      };

  
      verifyTransaction();
    } else {
      // setLoading(false)
      setError(true);
      setMessage("Transaction Canceled");
      setTimeout(() => navigate(`/booking`), 5000);
    }
  }, [location]);


  
  return (
    <div>
      {loading ? (
        "Loading ....."
      ) : (
        <>
          <Navbar logoURL={'../'}/>
          <div
            class="container-fluid vh-100"
            style={{ backgroundColor: "black" }}
          >
            <div class="row">
              <div className="col-sm-12 col-md-4 col-lg-2 "></div>

              <div className="col-sm-12 col-md-4 col-lg-8 ">
                {error ? (
                  <div className="text-center " style={{ marginTop: "10rem" }}>
                    <img
                      src={"https://i.gifer.com/76cI.gif"}
                      className="mt-5"
                      width={150}
                      height={150}
                    />
                    <h2
                      className="fw-bold text-center"
                      style={{ marginTop: 20 }}
                    >
                      {" "}
                      {message}
                    </h2>
                  </div>
                ) : (
                  <div className="text-center fs-4 text-white" style={{ marginTop: "5rem" }}>
                    <img
                      src="https://i.gifer.com/7efs.gif"
                      className="mt-5"
                      width={300}
                      height={300}
                    />
                    <h2
                      className="fw-bold fs-4 text-white text-white"
                      style={{ marginTop: 20 }}
                    >
                      {" "}
                      {message}
                    </h2>
                  </div>
                )}
              </div>

              <div className="col-sm-12 col-md-4 col-lg-2"></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default VerifyPay;
