import Navbar from "../Components/Navbar";
import "../Components/styles.css";
import AliceTwo from "../Components/AliceTwo";
import Footer from "../Components/Footer";
import { useSpring, animated } from "react-spring";
import { useEffect, useState } from "react";
import ImageGrid from "../Components/ImageGrid";
import ImageOverlay from "../Components/ImageOverlay";

function Gallery() {

  // const props = useSpring({
  //   to: { opacity: 1 },
  //   from: { opacity: 0 },
  //   config: { duration: 2000 }
  // });

  const [toggle, setToggle] = useState(false);

  const props = useSpring({
    transform: toggle ? "translateX(0%)" : "translateX(-100%)",
    config: { duration: 1000 },
    reset: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setToggle(!toggle);
    }, 2000);

    return () => clearInterval(interval);
  }, [toggle]);

  return (
    <div style={{ backgroundColor: "black" }}>
      <Navbar />

      <div
        className="container-fluid text-white  gallerypage"
        style={{ backgroundColor: "black" }}
      >
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 p-3">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />

            <h1 className=" gallerypageh1">Gallery</h1>
            <animated.p style={props} className="bookingpagep1">
              We do not give you just rooms to stay. We give you an environment
              so you can experience the best while on a holiday with us.
            </animated.p>
            <p>
              <a
                href="/contact"
                className="text-decoration-none p-2 fs-3 bg-dark text-white"
              >
                Contact Us
              </a>
            </p>
          </div>
          <div className="col-sm-12 col-md-5 col-lg-4">
            <br />
            <br />
            <br />
            <br />
            <div className="p-2 bg-light shadow rounded-4 ">
              <div className="m-3"></div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>

      {/*  */}

      <ImageGrid />
      <br />

      
   
    
      <section>
        {/* Gray Orchid SuiteCard */}
        <div className=" container-fluid">
          <div className="text-white m-4">
            <h1 className="fw-bold text-center row6h1">Gray Orchid Suite</h1>
            {/* <p className="fs-5">
              Seeking a complete experience of relaxation, refreshment, and
              rejuvenation, we provide it all under one roof.
            </p> */}
          </div>

          <div class="row ">

            <div class="col-sm-12 col-md-6 col-lg-6">
                <br/>
                <ImageOverlay imgsrc={"/INTERIOR PICTURES/INT-4.JPG"} title={'Sitting Room'} />
              {/* <img
                src="/INTERIOR PICTURES/INT(1).jpg"
                class="card-img-top"
                height={400}
                width={400}
                alt="..."
              />
              <div class="">
                <h5 class="text-white mt-3 fs-2 mb-2">Executive Bedrooms</h5>
              </div> */}
            </div>

            <div class=" col-sm-12 col-md-6 col-lg-6 ">
            <br/>
            <ImageOverlay imgsrc={"/INTERIOR PICTURES/INT(1).jpg"} title={'Master Bedroom'} />

              {/* <img
                src="/INTERIOR PICTURES/INT-12.JPG"
                class="card-img-top"
                height={400}
                width={400}
                alt="..."
              />
              <div class="card-body">
                <h5 class="text-white mt-3 fs-2 mb-2">Modern Kitchen</h5>
              </div> */}
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 ">
            <br/>
            <ImageOverlay imgsrc={"/INTERIOR PICTURES/DSC_5251.jpg"} title={'Dinning'} />


              {/* <img
                src="/INTERIOR PICTURES/INT-11.JPG"
                class="card-img-top"
                height={400}
                width={400}
                alt="..."
              />
              <div class="">
                <h5 class="text-white mt-3 fs-2 mb-2">Living Room</h5>
              </div> */}
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">
            <br/>
            <ImageOverlay imgsrc={"/INTERIOR PICTURES/DSC_5273.jpg"} title={'Interior Decor'} />

              {/* <img
                src="/INTERIOR PICTURES/DSC_5251.jpg"
                class="card-img-top"
                height={400}
                width={400}
                alt="..."
              />
              <div class="card-body">
                <h5 class="text-white mt-3 fs-2 mb-2">Standard Dinning Room</h5>
              </div> */}
            </div>
          </div>

         
        </div>
      </section>
      <br/>

      <section>
        {/* Inside Flamingo SuiteCard */}
        <div className=" container-fluid">
          <div className="text-white m-4">
            <h1 className="fw-bold text-center row6h1">Flamingo Suite</h1>
            {/* <p className="fs-5">
              Seeking a complete experience of relaxation, refreshment, and
              rejuvenation, we provide it all under one roof.
            </p> */}
          </div>

          <div class="row ">

            <div class="col-sm-12 col-md-6 col-lg-6">
                <br/>
                <ImageOverlay imgsrc={"/INTERIOR PICTURES/INT-7.JPG"} title={'Sitting Room'} />
              {/* <img
                src="/INTERIOR PICTURES/INT(1).jpg"
                class="card-img-top"
                height={400}
                width={400}
                alt="..."
              />
              <div class="">
                <h5 class="text-white mt-3 fs-2 mb-2">Executive Bedrooms</h5>
              </div> */}
            </div>

            <div class=" col-sm-12 col-md-6 col-lg-6 ">
            <br/>
            <ImageOverlay imgsrc={"/INTERIOR PICTURES/DSC_5082.jpg"} title={'Dinning'} />

              {/* <img
                src="/INTERIOR PICTURES/INT-12.JPG"
                class="card-img-top"
                height={400}
                width={400}
                alt="..."
              />
              <div class="card-body">
                <h5 class="text-white mt-3 fs-2 mb-2">Modern Kitchen</h5>
              </div> */}
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 ">
            <br/>
            <ImageOverlay imgsrc={"/INTERIOR PICTURES/INT-37.JPG"} title={'Convenience'} />


              {/* <img
                src="/INTERIOR PICTURES/INT-11.JPG"
                class="card-img-top"
                height={400}
                width={400}
                alt="..."
              />
              <div class="">
                <h5 class="text-white mt-3 fs-2 mb-2">Living Room</h5>
              </div> */}
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">
            <br/>
            <ImageOverlay imgsrc={"/INTERIOR PICTURES/INT-10.JPG"} title={'Interior Decor'} />

              {/* <img
                src="/INTERIOR PICTURES/DSC_5251.jpg"
                class="card-img-top"
                height={400}
                width={400}
                alt="..."
              />
              <div class="card-body">
                <h5 class="text-white mt-3 fs-2 mb-2">Standard Dinning Room</h5>
              </div> */}
            </div>
          </div>

         
        </div>
      </section>
    
    <br/>
    <br/>

      {/* <div className="container-fluid">
                    <Carousels/>
                </div> */}

      
      {/* <section>
      <div className="container-fluid mt-5">
        <div className="row mb-5">
          <div className="col-sm-12 col-md-6 col-lg-6 ">
              <div className="row-four mt-5">
              <span id="hideeltonsmscreen">
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </span>
              <br /> <br /> <br /> <br /> <br />
              <br /> <br /> <br /> <br /> <br />
              <br /> <br /> <br /> <br /> <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              </div>
          
           
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="mt-4">
              <iframe
                width="100%"
                height="500"
                src="https://www.youtube.com/embed/eEGVm6vLmaQ?autoplay=0&mute=1"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      </section> */}
 
        <section>
        <div className="container-fluid mt-3">
                <div className="row">
                  <div className="col-sm-12 col-md-1 col-lg-2"></div>
                  <div className="col-sm-12 col-md-10 col-lg-8">
                    <div className="w-100 h-100 text-center">
                      <br/>
                      <br/>
                      <br/>
                      {/* <h1
                        className="text-monospace mt-4 mb-1"
                        style={{ fontSize: "60px" }}
                      >
                        What you should expect
                      </h1>
                      <h5 className=" text-monospace mb-5">
                        Experience the best comfort you deserve
                      </h5> */}

                      <iframe
                        width="100%"
                        height="450"
                        src="https://www.youtube.com/embed/zukXPwt_kIU?autoplay=1&mute=0"
                      ></iframe>
                    </div>

                    {/* <video controls className="w-100" autoPlay src="https://www.youtube.com/watch?v=LXb3EKWsInQ" >
                                    
                      </video> */}
                  </div>
                  <div className="col-sm-12 col-md-1 col-lg-2"></div>
                </div>
                <br />
                <br />
              </div>
              <div></div>
        </section>
        <br />
      <br />
      <br />
      <section>
        <div className="container-fluid mt-5">
          <AliceTwo />
        </div>
      </section>

      <br />
      <br />
      <br />

     


    {/* <section title="Best Suite">
     <div className="text-white m-4">
        <h1 className="fw-bold row6h1 text-center">Best Suites </h1>
        <p className="fs-5  text-center">
        </p>
      </div>
      <nav>
        <div
          class="nav nav-tabs justify-content-center"
          id="nav-tab"
          role="tablist"
        >
          <button
            class="nav-link active me-3 fs-3 "
            id="nav-home-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-home"
            type="button"
            role="tab"
            aria-controls="nav-home"
            aria-selected="true"
          >
            {" "}
            Flamingo
          </button>
          <button
            class="nav-link fs-3  "
            id="nav-profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#nav-profile"
            type="button"
            role="tab"
            aria-controls="nav-profile"
            aria-selected="false"
          >
            Gray Orchid
          </button>
        </div>
      </nav>
      <div class="tab-content" id="nav-tabContent">
        <div
          class="tab-pane fade show active"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
          tabindex="0"
        >
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <img
                src="/INTERIOR PICTURES/INT-7.JPG"
                classsName="card-img-top"
                height={400}
                width={400}
                alt="..."
              />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <img
                src="/INTERIOR PICTURES\DSC_5236.jpg"
                classsName="card-img-top"
                height={400}
                width={400}
                alt="..."
              />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <img
                src="/INTERIOR PICTURES\INT-10.JPG"
                classsName="card-img-top"
                height={400}
                width={400}
                alt="..."
              />
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
          tabindex="0"
        >
        
          <div className="row">
            <div className="col-sm-12 col-md-4 col-lg-4">
              <img
                src="/INTERIOR PICTURES\INT-4.JPG"
                classsName="card-img-top"
                height={400}
                width={400}
                alt="..."
              />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <img
                src="/INTERIOR PICTURES\DSC_5251.jpg"
                classsName="card-img-top"
                height={400}
                width={400}
                alt="..."
              />
            </div>
            <div className="col-sm-12 col-md-4 col-lg-4">
              <img
                src="/INTERIOR PICTURES\DSC_5273.jpg"
                classsName="card-img-top"
                height={400}
                width={400}
                alt="..."
              />
            </div>
          </div>
        </div>
      </div>
    </section> */}

     

      <br />
      <br />
      <br />

      {/* next Sectioon */}
     
<section>
   <div className="mt-5">
        {/* <div className="row7"> */}
        <div className=""style={{  backgroundImage: "linear-gradient(to right, red,orange,yellow)"}}> 

          {/* <img src="/INTERIOR PICTURES/DSC_5011.jpg" className="row7img"/> */}
          <div className="text-center text-black">
            <br />
            <br />
            <br />
            <br />
            <br />
            <h1 className="fw-bold row7h1">
              Let's helps you book your stay 
              {/* <span className="text-decoration-underline"> AludyHomes </span>
              Today! */}
            </h1>
            <br />
            <br />
            <p className="mt-5 row7p1 fs-2 ">
       
              <a className="text-decoartion-none text-black" href="https://aludys.com/shop">
               
                <button className="p-2">GET STARTED NOW</button>​
              </a>
            </p>
            <br />
            <br />
            <br />
            <br />
            <br /><br />
            {/* <p className=" fw-bold row7p2 bg-warning ">
              <FaPhone /> +234 803 455 0997​
            </p> */}
          </div>
        </div>
      </div>
</section>
      {/* footer */}

      <Footer />

      {/*  */}
    </div>
  );
}

export default Gallery;
