import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styles.css'

const testimonials = [
  {
    img: 'assets/img/testimonials/testimonials-1.jpg',
    name: 'Mrs. Funmi Ajayi',
    title: 'CEO & Founder',
    text: '“My experience with Aludy’s Homes short let was outstanding. The location was perfect, close to major attractions and public transport. The apartment was spacious, modern, and had a cozy ambiance that made my stay very pleasant. The management team was always just a call away and resolved any minor issues promptly. I will definitely choose Aludy’s Homes again for my future visits.”',
  },
  {
    img: 'assets/img/testimonials/testimonials-2.jpg',
    name: 'Danjuma',
    title: 'Real Estate Investor',
    text: '“I couldn’t have asked for a better short let experience than with Aludy’s Homes. The apartment exceeded my expectations with its modern amenities, comfortable beds, and stunning views. The customer service was top-notch, always ready to assist with any request. Whether for business or leisure, Aludy’s Homes is the perfect choice for a short stay.”',
  },
  {
    img: 'assets/img/testimonials/testimonials-3.jpg',
    name: 'Mr. Okafor',
    title: 'Business Mogul',
    text: 'Staying at Aludy’s Homes was an absolute delight! The short-let apartment was impeccably clean, beautifully furnished, and equipped with all the amenities we needed. The booking process was smooth, and the staff was incredibly responsive and helpful throughout our stay. It felt like a home away from home. I highly recommend Aludy’s Homes for anyone looking for a comfortable and hassle-free short-term rental.”',
  }
//   ,
//   {
//     img: 'assets/img/testimonials/testimonials-4.jpg',
//     name: 'Matt Brandon',
//     title: 'Freelancer',
//     text: 'I so much admire the way their sales representative serves me and other customers I have recommended to them. Reliance Properties is all out to make people\'s lives better using their product and services.',
//   },
//   {
//     img: 'assets/img/testimonials/testimonials-5.jpg',
//     name: 'John Larson',
//     title: 'Entrepreneur',
//     text: 'Having little to no idea about real estate investing, I learned about the Reliance platform and have tremendously increased my income to over $150k in two years. Reliance Properties has made it easy for me to manage multiple properties also.',
//   }

];

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false, // Disable navigation arrows

  };

  return (
      <div className="container-fluid ">
        <div className="section-title">
          <h2 className="fs-2">Testimonials</h2>
        </div>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-item text-center">
              {/* <img src={testimonial.img} className="testimonial-img text-center" alt={testimonial.name} /> */}
           
              <p className='text-white textp1'>
                <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                {testimonial.text}
                <i className="bx bxs-quote-alt-right quote-icon-right"></i>
              </p>
                 <h3 className='text-white'>{testimonial.name}</h3>
              <h4 className='text-white'>{testimonial.title}</h4>
            </div>
          ))}
        </Slider>
      </div>
   
  );
}

export default Testimonials;
