import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { forgotPasswordSchema } from "../Components/FormSchema";
import ApiServices from "../Services/ApiServices";
import { displayAlert } from "../Components/Utils/Appfunctions";
import "../Components/styles.css";
import Navbar from "../Components/Navbar";

function ForgotPasswordForm() {
  const initialValues = {
    email: "",
  };

  const navigate = useNavigate();

  const [alertmessage, setAlertmessage] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const onSubmit = async (values, actions) => {

    try {
      if (!values) return;
      console.log("submitted", values);
      const { success, message } = await ApiServices.postRequestNoAuth(
        "/controllers/forgetpasswordcode.php",
        values
      );
      setAlertmessage(true);
      if (success) {
        setMessage(message);
        // setTimeout(() => setAlertmessage(false), 2000);
      } 
      // else {
      //   setMessage(message);
      //   setIsError(true);
      //   setTimeout(() => setAlertmessage(false), 3000);
      //   actions.resetForm();
      // }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/*  */}
      <Navbar />
      <div className="container-fluid" style={{ backgroundColor: "black" }}>
        <div className="row  ">
          <div className="col-sm-12 col-md-12 col-lg-4"></div>
          <div className="col-sm-12 col-md-12 col-lg-4 vh-100">
            {/* //  start */}
            <div className="p-2 bg-light shadow mt-5 rounded-4">
              <div className="m-3">

              {alertmessage ? (
                        <h5 className=" mt-3 p-3">
                           {" "}
                          {message}
                        </h5>           
                     ) : (
                        <>
                       
                       {/* form */}
                 <Formik
                  initialValues={initialValues}
                  validationSchema={forgotPasswordSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      class="row g-3 text-center  mt-5"
                    >
                      <div className="text-center">

                     

                        <h5 className=" mt-3">
                          {" "}
                          Enter your Email Address to Reset password{" "}
                        </h5>
                      </div>

                      <div class="col-md-12">
                        <label
                          for="inputCity"
                          class="form-label float-start"
                        ></label>
                        <br></br>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          class="form-control-lg w-100 "
                          id="inputCity"
                        />
                        <br />
                        {errors.email && (
                          <small
                            style={{ fontSize: 10 }}
                            className=" text-danger float-start"
                          >
                            {errors.email}
                          </small>
                        )}
                      </div>

                      <div class="col-12 mt-5">
                        <button
                          disabled={isSubmitting}
                          type="submit"
                          class="btn btn-lg btn-dark font-monospace"
                        >
                          {isSubmitting ? (
                            <>
                             
                                <span className="ms-3"> Submitting </span>
                              
                            </>
                          ) : (
                            "Send Link"
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>

                       </>)}

            
              </div>
            </div>
            {/* //  end  */}
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4"></div>
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordForm;
