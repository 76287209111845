import Gallery from "../Components/Gallery";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { v4 as uuidv4 } from 'uuid';
import { BookingFormSchema } from "../Components/FormSchema";
import ApiServices from "../Services/ApiServices";
import "react-datepicker/dist/react-datepicker.css";
import Navbar from "../Components/Navbar";
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import dayjs from "dayjs";
import "../Components/styles.css";


function BookingForm() {
  const navigate = useNavigate();

  const [bookings, setBookings]=useState([])

  const [alertmessage, setAlertmessage] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [amountPerday, setAmountPay] = useState(50000);
  const [numberOfDays, setNumberofDays] = useState(0);

  const uuid = uuidv4();

  const initialValues = {
    fullname: '',
    email: '',
    phonenumber: '',
    checkIn: "",
    checkOut: '',
    adults: '',
    children: '',
    amountPerday: amountPerday,
    numberOfDays: 0,
    amount: 0,
    invoiceId: uuid

  };

  const calculateDaysAndAmount = (checkIn, checkOut) => {
    if (checkIn && checkOut) {
      const startDate = dayjs(checkIn);
      const endDate = dayjs(checkOut);
      const days = endDate.diff(startDate, 'day');
      return { days, amount: days * amountPerday };
    }
    return { days: 0, amount: 0 };
  };

  const onSubmit = async (values, actions) => {
      
    try {
      const { days, amount } = calculateDaysAndAmount(values.checkIn, values.checkOut);
      values.numberOfDays = days;
      values.amount = amount;
      console.log(" smile=", values);
      ApiServices.setLocalStorage("cart", values);
      navigate("/cart");
    } catch (error) {
      console.error("Login error:", error);
      setMessage("Network error occurred.");
      setIsError(true);
      setTimeout(() => setAlertmessage(false), 3000);
    }
  };

  useEffect(() => {
    const getBookings = async () => {
      try {
        const response = await ApiServices.getRequestNoAuth("/controllers/booking.php");
        if (response) {
          const { bookings, success } = response;
          if (success) {
            setBookings(bookings);
          } else {
            setMessage("Unexpected error occurred.");
            setIsError(true);
            setTimeout(() => setAlertmessage(false), 3000);
          }
        }
      } catch (error) {
        console.error("Login error:", error);
        setMessage("Network error occurred.");
        setIsError(true);
        setTimeout(() => setAlertmessage(false), 3000);
      }
    };

    getBookings();
  }, []);

  const filterDate = date => {
    for (const day of bookings) {
      if (moment(date).isSame(day)) { return false; }
    }
    return true;
  };

  const checkForDateClash = (startDate, endDate) => {
    try {
      for (const bookedDay of bookings) {
        if (moment(bookedDay).isBetween(startDate, endDate, 'days', '[]')) {
          return true; //date clash
        }
      }
      return false;
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <Navbar />
      <br/>
      <br/><br/>
      <div className="container-fluid text-white  bookpage" style={{ backgroundColor: "black" }}>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-6 p-3">
             <br/>
             <br/>
            
            
             <h1 className=" bookingpageh1">Let us help you enjoy your vacations</h1>
              <p className="bookingpagep1">If you are looking for a home away from home, just walk in here. You can get both, an incredible and relaxing experience while you are on a holiday. Take a closer look into the rooms we offer and pick the one for you.

              </p>

              <iframe width="100%" height="400"
                            src="https://www.youtube.com/embed/eEGVm6vLmaQ?autoplay=0&mute=1">
                            </iframe>
          </div>
          <div className="col-sm-12 col-md-5 col-lg-4">
          <br/>
              <br/>
              <br/>
              <br/>
            <div className="p-2 bg-light shadow ">
             
              <div className="m-3">
                <Formik
                  initialValues={initialValues}
                  validationSchema={BookingFormSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue
                  }) => (
                    <form onSubmit={handleSubmit} class="row g-3 text-center">
                      <div className="text-center">
                        <h5 className="text-dark">Kindly provide booking details</h5>
                      </div>

                      <div class="col-md-12">
                        <label for="inputCity" class="form-label text-dark float-start"></label>
                        <br></br>
                        <input
                          type="text"
                          name="fullname"
                          placeholder="Full Name"
                          value={values.fullname}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          class="form-control-lg w-100 "
                          id="inputCity"
                        />
                        <br />
                        {errors.fullname && (
                          <small style={{ fontSize: 10 }} className="text-danger float-start">
                            {errors.fullname}
                          </small>
                        )}
                      </div>

                      <div class="col-md-12">
                        <label for="inputCity" class="form-label text-dark float-start"></label>
                        <br></br>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          class="form-control-lg w-100"
                          id="inputCity"
                        />
                        <br />
                        {errors.email && (
                          <small style={{ fontSize: 10 }} className="text-danger float-start">
                            {errors.email}
                          </small>
                        )}
                      </div>

                      <div class="col-md-12">
                        <label for="inputCity" class="form-label text-dark float-start"></label>
                        <br></br>
                        <input
                          type="text"
                          name="phonenumber"
                          placeholder="Phone Number"
                          value={values.phonenumber}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          class="form-control-lg w-100"
                          id="inputCity"
                        />
                        <br />
                        {errors.phonenumber && (
                          <small style={{ fontSize: 10 }} className="text-danger float-start">
                            {errors.phonenumber}
                          </small>
                        )}
                      </div>

                      <div className="row">
                        <div class="col-6">
                          <label for="inputCity" class="form-label text-dark float-start">Checking In</label>
                          <br></br>
                          <ReactDatePicker
                            id="checkIn"
                            name="checkIn"
                            placeholderText="Select check-in date"
                            minDate={dayjs().toDate()}
                            maxDate={dayjs().add(3, 'month').toDate()}
                            filterDate={filterDate}
                            selected={values.checkIn ? dayjs(values.checkIn).toDate() : null}
                            onChange={date => setFieldValue('checkIn', date)}
                            className={`form-control ${touched.checkIn && errors.checkIn ? 'is-invalid' : ''}`}
                          />
                        </div>

                        <div class="col-6">
                          <label for="inputCity" class="form-label text-dark float-start">ChecKing Out</label>
                          <br></br>
                          <ReactDatePicker
                            disabled={!values.checkIn}
                            placeholderText="Select checkout date"
                            minDate={dayjs(values.checkIn).add(1, "day").toDate()}
                            filterDate={filterDate}
                            selected={values.checkOut ? dayjs(values.checkOut).toDate() : null}
                            onChange={date => {
                              setFieldValue("checkOut", date);
                              const { days, amount } = calculateDaysAndAmount(values.checkIn, date);
                              setFieldValue("numberOfDays", days);
                              setFieldValue("amount", amount);
                            }}
                            className={`form-control ${touched.checkOut && errors.checkOut ? 'is-invalid' : ''}`}
                          />
                        </div>
                      </div>

                      <div class="col-md-12">
                        <label for="inputCity" class="form-label float-start"></label>
                        <br></br>
                        <input
                          type="number"
                          name="adults"
                          placeholder="Adults"
                          value={values.adults}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          class="form-control-lg w-100"
                          id="inputCity"
                        />
                        <br />
                        {errors.adults && (
                          <small style={{ fontSize: 10 }} className="text-danger float-start">
                            {errors.adults}
                          </small>
                        )}
                      </div>

                      <div class="col-md-12">
                        <label for="inputCity" class="form-label float-start"></label>
                        <br></br>
                        <input
                          type="number"
                          name="children"
                          placeholder="Number of children"
                          value={values.children}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          class="form-control-lg w-100"
                          id="inputCity"
                        />
                        <br />
                        {errors.children && (
                          <small style={{ fontSize: 10 }} className="text-danger float-start">
                            {errors.children}
                          </small>
                        )}
                      </div>

                      <div class="col-12 mt-5">
                        <button
                          disabled={isSubmitting}
                          type="submit"
                          class="btn btn-lg btn-dark font-monospace"
                        >
                          {isSubmitting ? (
                            <>
                              <span class="spinner-grow spinner-grow-sm mt-5 bg-dark" role="status" aria-hidden="true">
                                <span className="ms-3">Submitting</span>
                              </span>
                            </>
                          ) : (
                            "Book Now"
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
            
          </div>
        </div>
        <br/>
            <br/>
            <br/>
            <br/>
      </div>
    </>
  );
}

export default BookingForm;