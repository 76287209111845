import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { loginSchema } from "../Components/FormSchema";
import ApiServices from "../Services/ApiServices";
import { displayAlert } from "../Components/Utils/Appfunctions";
import "../Components/styles.css";
import { constants } from "../Components/Utils/Constants";

function AdminLoginForm() {
    
  const initialValues = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();

  const [alertmessage, setAlertmessage] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const onSubmit = async (values, actions) => {
    try {
      if (!values) return;
      console.log("submitted", values);
      const response = await ApiServices.postRequestNoAuth(
        "/controllers/login.php",
        values
      );
      // const response = await ApiServices.postRequestNoAuth("http://localhost:5000/controllers/login.php", values);

      console.log("response=", response);
      if (response) {
        const { success, message, userInfo } = response;
        setAlertmessage(true);

        if (success) {
          setMessage(message);
          setIsError(false);
          setTimeout(() => setAlertmessage(false), 2000);
          var str = ApiServices.setCookie("user", userInfo, 7);
          // Optionally, redirect to another page:
          window.location.replace(`${constants.clientURL}`);
        } else {
          setMessage(message);
          setIsError(true);
          setTimeout(() => setAlertmessage(false), 3000);
          actions.resetForm();
        }
      } else {
        setMessage("Unexpected error occurred.");
        setIsError(true);
        setTimeout(() => setAlertmessage(false), 3000);
        actions.resetForm();
      }
    } catch (error) {
      console.error("Login error:", error);
      setMessage("Network error occurred.");
      setIsError(true);
      setTimeout(() => setAlertmessage(false), 3000);
      actions.resetForm();
    }
  };

  return (
    <>
      <div className="container-fluid" style={{ backgroundColor: "black" }}>
        {displayAlert({ alertmessage, isError, message })}
        <div className="row">
          <div className="col-lg-4"></div>
          <div className="col-lg-4 vh-100">
            <div className="p-2 bg-light shadow mt-5 rounded-4">
              <div className="m-3">
                <Formik
                  initialValues={initialValues}
                  validationSchema={loginSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      className="row g-3 text-center mt-5"
                    >
                      <div className="text-center">
                        <img
                          src="https://aludys.com/web/image/website/1/logo/Aludys%20Homes?unique=72afd62"
                          height={50}
                          width={50}
                          alt="logo"
                        />
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="inputCity"
                          className="form-label float-start"
                        ></label>
                        <br />
                        <input
                          type="email"
                          name="email"
                          placeholder="email"
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          className="form-control-lg w-100"
                          id="inputCity"
                        />
                        <br />
                        {errors.email && (
                          <small
                            style={{ fontSize: 10 }}
                            className="text-danger float-start"
                          >
                            {errors.email}
                          </small>
                        )}
                      </div>
                      <div className="col-md-12">
                        <label
                          htmlFor="inputCity"
                          className="form-label float-start"
                        ></label>
                        <br />
                        <input
                          type="password"
                          name="password"
                          placeholder="*********************"
                          value={values.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          className="form-control-lg w-100"
                          id="inputCity"
                        />
                        <br />
                        {errors.password && (
                          <small
                            style={{ fontSize: 10 }}
                            className="text-danger float-start"
                          >
                            {errors.password}
                          </small>
                        )}
                        <a
                          href="/forgot-password"
                          className="text-dark fw-bold text-decoration-none float-end"
                        >
                          forgot password
                        </a>
                      </div>
                      <div className="col-12">
                        <button
                          disabled={isSubmitting}
                          type="submit"
                          className="btn btn-lg btn-dark font-monospace"
                        >
                          {isSubmitting ? (
                            <>
                             
                                <span className="ms-3"> Submitting </span>
                            
                            </>
                          ) : (
                            "login"
                          )}
                        </button>
                      </div>
                      <p className="text-center">
                        Dont have? create a free account{" "}
                        <a href="/signup"> here</a>
                      </p>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="col-lg-4"></div>
        </div>
      </div>
    </>
  );
}

export default AdminLoginForm;
