import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { ResetPasswordSchema } from "../Components/FormSchema";
import ApiServices from "../Services/ApiServices";
import { displayAlert } from "../Components/Utils/Appfunctions";
import "../Components/styles.css";
import Navbar from "../Components/Navbar";

function ResetPasswordForm() {
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
   
       // const token = queryParams.get("token");
       const token = "f3c0ee7f0d6adfdbbbc8585f3d46bc07";
   
       console.log("token = ", token);
   
     const navigate = useNavigate()
   
     const [alertmessage, setAlertmessage] = useState(false);
     const [message, setMessage] = useState("");
     const [isError, setIsError] = useState(false);
   
   const initialValues = { 
           password: "",
           confirmpassword: "",
           passwordtoken: token
       };
   
//   useEffect(() => {

//     if (token !== null) {
      
//       const verifyTransaction = async () => {
//         try {
//           const response = await ApiServices.getRequestNoAuth(
//             `/controllers/verifyresetpassword.php?token=${token}`
//           );
            
//           if (response) {

//                 const {success, message} = response;
//                 if (success){
                 
//                   setIsError(false)
//                     setMessage(message);
//                     setTimeout(() => navigate(`/reservations`), 10000);
//                 }else{
                
//                   setIsError(true)
//                   setMessage(message);
//                   setTimeout(() => navigate(`/logoin`), 10000);
//                 }
           
//           } else {
            
//             setIsError(true)
//             setMessage("Network Error");
//             setTimeout(() => navigate(`/booking`), 10000);
//           }
//           // Redirect or perform any additional actions here
//         } catch (error) {
//           setMessage("Transaction verification failed. Please try again.");
//           console.error("Transaction verification error:", error);
//         }
//       };
//       verifyTransaction();
//     } else {
//       navigate(`/`)
//     }



//   }, []);

  const onSubmit = async (values, actions) => {

    try {
      if (!values) return;
      console.log("suresetpass", values);
      const { success, message } = await ApiServices.postRequestNoAuth(
        "/controllers/resetpasswordcode.php",
        values
      );
      setAlertmessage(true);
      if (success) {
        setMessage(message);
        setTimeout(() => setAlertmessage(false), 2000);
      } 
      else {
        setMessage(message);
        setIsError(true);
        setTimeout(() => setAlertmessage(false), 3000);
        actions.resetForm();
      }
    } catch (error) {
      console.log(error);
      actions.resetForm();
    }
  };

  

  return (
    <>
      {/*  */}
      <Navbar />

      <div className="container-fluid" style={{ backgroundColor: "black" }}>
        <div className="row  ">
          <div className="col-sm-12 col-md-12 col-lg-4"></div>
          <div className="col-sm-12 col-md-12 col-lg-4 vh-100">
            {/* //  start */}
            <div className="p-2 bg-light shadow mt-5 rounded-4">
              <div className="m-3">

              {alertmessage ? (
                        <h5 className=" mt-3 p-3">
                           {" "}
                          {message}
                        </h5>           
                     ) : (
                        <>
                       
                       {/* form */}
                 <Formik
                  initialValues={initialValues}
                  validationSchema={ResetPasswordSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      class="row g-3 text-center  mt-5"
                    >
                      <div className="text-center">

                        <h5 className=" mt-3">
                       
                          Reset Your Password
                        </h5>
                      </div>

                      <div class="col-md-12">
                        <label
                          for="inputCity"
                          class="form-label float-start text-dark"
                        >New Password</label>
                        <br></br>
                        <input
                          type="password"
                          name="password"
                          placeholder="************"
                          value={values.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          class="form-control-lg w-100 "
                          id="inputCity"
                        />
                        <br />
                        {errors.password && (
                          <small
                            style={{ fontSize: 10 }}
                            className=" text-danger float-start"
                          >
                            {errors.password}
                          </small>
                        )}
                      </div>

                      <div class="col-md-12">
                        <label
                          for="inputCity"
                          class="form-label float-start text-dark"
                        >Confirm Password</label>
                        <br></br>
                        <input
                          type="password"
                          name="confirmpassword"
                          placeholder="***********"
                          value={values.confirmpassword}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          class="form-control-lg w-100 "
                          id="inputCity"
                        />
                        <br />
                        {errors.confirmpassword && (
                          <small
                            style={{ fontSize: 10 }}
                            className=" text-danger float-start"
                          >
                            {errors.confirmpassword}
                          </small>
                        )}
                      </div>

                      <div class="col-12 mt-5">
                        <button
                          disabled={isSubmitting}
                          type="submit"
                          class="btn btn-lg btn-dark font-monospace"
                        >
                          {isSubmitting ? (
                            <>
                              
                                <span className="ms-3"> Submitting </span>
                             
                            </>
                          ) : (
                            "Submit"
                          )}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>

                       </>)}

            
              </div>
            </div>
            {/* //  end  */}
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4"></div>
        </div>
      </div>
    </>
  );
}

export default ResetPasswordForm;
