import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { ContactFormSchema  } from "../Components/FormSchema";
import ApiServices from "../Services/ApiServices";
import { displayAlert } from "../Components/Utils/Appfunctions";
import "../Components/styles.css";
import Navbar from "../Components/Navbar";

function ContactForm() {
  const initialValues = {
    fullname: "",
    email:"",
     subject:'',
     description:''
  };

  const navigate = useNavigate();

  const [alertmessage, setAlertmessage] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const onSubmit = async (values, actions) => {
   

    try {
      if (!values) return;
      console.log("submitted", values);
      const response = await ApiServices.postRequestNoAuth("/controllers/contact.php", values);
       
      console.log("response=", response);
      if (response) {
        const { success, message } = response;
        setAlertmessage(true);
        
        if (success) {
          setMessage(message);
          setIsError(false);
          setTimeout(() => setAlertmessage(false), 2000);
          // Optionally, redirect to another page:
        } else {
          setMessage(message);
          setIsError(true);
          setTimeout(() => setAlertmessage(false), 3000);
          actions.resetForm();
        }
      } else {
        setMessage("Unexpected error occurred.");
        setIsError(true);
        setTimeout(() => setAlertmessage(false), 3000);
        actions.resetForm();
      }
    } catch (error) {
      console.error("Login error:", error);
      setMessage("Network error occurred.");
      setIsError(true);
      setTimeout(() => setAlertmessage(false), 3000);
      actions.resetForm();
    }
  };


  
  return (

     <>
     <Navbar />
     <br/>
     <br/><br/>
     <div className="container-fluid text-white  contactpage" style={{ backgroundColor: "black" }}>
       <div className="row">
         <div className="col-sm-12 col-md-6 col-lg-6 p-3">
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
           
            <h1 className=" bookingpageh1">Contact Us</h1>
             <p className="bookingpagep1">
               Our customer service will respond to your enquiries immediately
             </p>

            
         </div>
         <div className="col-sm-12 col-md-5 col-lg-4">
         <br/>
             <br/>
             <br/>
             <br/>
           <div className="p-2 bg-light shadow ">
            
             <div className="m-3">
             <Formik
                  initialValues={initialValues}
                  validationSchema={ContactFormSchema }
                  onSubmit={onSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      class="row g-3 text-center  mt-5"
                    >
                      <div className="text-center">
                        
                        <h5 className=" text-dark mt-3"> Please fill the contact form, Our customer care will reach out to you as soon as possible </h5>
                      </div>

                      <div class="col-md-12">
                        <label
                          for="inputCity"
                          class="form-label float-start"
                        ></label>
                        <br></br>
                        <input
                          type="text"
                          name="fullname"
                          placeholder="Fullname"
                          value={values.fullname}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          class="form-control-lg w-100 border border-dark border-3 border-end-0 border-start-0 border-top-0"
                          id="inputCity"
                        />
                        <br />
                        {errors.fullname && (
                          <small
                            style={{ fontSize: 10 }}
                            className=" text-danger float-start"
                          >
                            {errors.fullname}
                          </small>
                        )}
                      </div>

                      <div class="col-md-12">
                        <label
                          for="inputCity"
                          class="form-label float-start"
                        ></label>
                        <br></br>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          class="form-control-lg w-100 border border-dark border-3 border-end-0 border-start-0 border-top-0 "
                          id="inputCity"
                        />
                        <br />
                        {errors.email && (
                          <small
                            style={{ fontSize: 10 }}
                            className=" text-danger float-start"
                          >
                            {errors.email}
                          </small>
                        )}
                      </div>

                      <div class="col-md-12">
                        <label
                          for="inputCity"
                          class="form-label float-start"
                        ></label>
                        <br></br>
                        <input
                          type="text"
                          name="subject"
                          placeholder="Subject"
                          value={values.subject}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          class="form-control-lg w-100 border border-dark border-3 border-end-0 border-start-0 border-top-0 "
                          id="inputCity"
                        />
                        <br />
                        {errors.subject && (
                          <small
                            style={{ fontSize: 10 }}
                            className=" text-danger float-start"
                          >
                            {errors.subject}
                          </small>
                        )}

                      </div>

                      <div class="col-md-12">
                        <label
                          for="inputCity"
                          class="form-label float-start"
                        ></label>
                        <br></br>
                        <textarea
                          type="text"
                          name="description"
                          placeholder="Type your Message"
                          value={values.description}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          class="form-control-lg w-100 border border-dark border-3 border-end-0 border-start-0 border-top-0 "
                          id="inputCity"
                        />
                        <br />
                        {errors.description && (
                          <small
                            style={{ fontSize: 10 }}
                            className=" text-danger float-start"
                          >
                            {errors.description}
                          </small>
                        )}
                      </div>

                   

                     

                      <div class="col-12">
                        <button
                          disabled={isSubmitting}
                          type="submit"
                          class="btn btn-lg btn-dark font-monospace"
                        >
                          {isSubmitting ? (
                            <>
                             
                                <span className="ms-3"> Submitting </span>
                             
                            </>
                          ) : (
                            "send message"
                          )}
                        </button>
                      </div>

                      
                    </form>
                  )}
                </Formik>
             </div>
           </div>
           
         </div>
       </div>
       <br/>
           <br/>
           <br/>
           <br/>
     </div>
   </>
  );
}

export default ContactForm;
