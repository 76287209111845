import { useContext, useEffect } from "react";
import Navbar from "../Components/Navbar";
import { UserContext } from "../Context/Authcontext";
import { Navigate } from "react-router-dom";
import DashSideBar from "../Components/DashSideBar";

function Settings() {
  const user = useContext(UserContext);

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      <Navbar />
      <div className="container-fluid">
        <div className="row">
          <div className="col-2 ">
             <DashSideBar />
          </div>
          <div className="col-8">
            {/*  */}
            <div className="mt-4" style={{ backgroundColor: "black" }}>
              <div className="text-dark bg-light mt-4">
                <h3 className="p-2">Profile</h3>

                <div class=" mt-4 table-responsive p-2 ">
                  <table class="table table-light table-hover">
                    <thead>
                      <tr className="justify-content-between">
                        <th
                          className="bg-dark text-white "
                          style={{ fontSize: "10px" }}
                          scope="col"
                        >
                          TRANSACTION ID
                        </th>
                        <th
                          className="bg-dark text-white "
                          style={{ fontSize: "10px" }}
                          scope="col"
                        >
                          DATE
                        </th>
                        <th
                          className="bg-dark text-white "
                          style={{ fontSize: "10px" }}
                          scope="col"
                        >
                          TAG
                        </th>
                        <th
                          className="bg-dark text-white"
                          style={{ fontSize: "10px" }}
                          scope="col"
                        >
                          AMOUNT
                        </th>
                        <th
                          className="bg-dark text-white"
                          style={{ fontSize: "10px" }}
                          scope="col"
                        >
                          STATUS
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr className="justify-content-between">
                        <th
                          className="bg-dark text-white "
                          style={{ fontSize: "10px" }}
                          scope="col"
                        ></th>
                        <th
                          className="bg-dark text-white "
                          style={{ fontSize: "10px" }}
                          scope="col"
                        ></th>
                        <th
                          className="bg-dark text-white "
                          style={{ fontSize: "10px" }}
                          scope="col"
                        ></th>
                        <th
                          className="bg-dark text-white"
                          style={{ fontSize: "10px" }}
                          scope="col"
                        ></th>
                        <th
                          className="bg-dark text-white"
                          style={{ fontSize: "10px" }}
                          scope="col"
                        ></th>
                      </tr>
                      {/* { userTransactions.length <  1 ? (<h6 className= 'mt-2 text-muted'>No Transactions Available</h6>) : (  
                         
                         userTransactions.map((transaction, index)=> (
                            <TransactionList index={index} transaction={transaction}/>
                         ))
                        
                       ) 
                     } */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
