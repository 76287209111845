import { useContext, useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import { UserContext } from "../Context/Authcontext";
import { Navigate } from "react-router-dom";
import ApiServices from "../Services/ApiServices";
import MyReservationList from "../Components/myReservationList";
import DashSideBar from "../Components/DashSideBar";
import '../Components/styles.css'

function Reservations() {

   const user = useContext(UserContext)

    const [myreservations, setReservations] = useState([])

    const [alertmessage, setAlertmessage] = useState(false);
    const [message, setMessage] = useState("");
    const [isError, setIsError] = useState(false);


    useEffect(()=>{

        const fetchReservations = async () => {
          try {
            const response = await ApiServices.getRequestNoAuth(`/controllers/userreservations.php?email=${user.email}`);  

            console.log("respesrds", response.myReservations);
            if (response) {
              const { myReservations, success } = response;
              
              if (success) {
                setReservations(myReservations);
                // Optionally, redirect to another page:
              } 
            // else {
            //     setMessage(message);
            //     setIsError(true);
            //     setTimeout(() => setAlertmessage(false), 3000);
            //     actions.resetForm();
            //   }
            } else {
              setMessage("Unexpected error occurred.");
              setIsError(true);
              setTimeout(() => setAlertmessage(false), 3000);
            }
          } catch (error) {
            console.error("Login error:", error);
            setMessage("Network error occurred.");
            setIsError(true);
            setTimeout(() => setAlertmessage(false), 3000);
          }
        };

        fetchReservations();
      }, [])


if (!user){
      return <Navigate to="/login" replace/>
    }




    return ( 
      <>
      <Navbar />
      <div className="container-fluid" >
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 vh-100">
            <br />
            <br />  <br />
            <br />
            <div className=" bg-light shadow mt-5 rounded-4">
              
              <div className="" >
                  <br/>
                 <div className="text-dark bg-light mt-4">
                 
                 
                <h3 className="mt-2 fw-bold fs-1 m-1">Reservations</h3>
                  
                <div class=" mt-4 table-responsive  p-2 ">

               
                <span className="float-end mb-2">
                   <a className="rounded-3 shadow btn btn-sm  btn-success text-white text-decoration-none me-1" href="/booking">
                      + create booking
                    </a>
                      <button class=" rounded-3 shadow btn btn-sm btn-danger" type="button"
                      data-bs-toggle="modal" data-bs-target="#exampleModal1">
                      cancel booking
                    </button>
                  </span>


                  <table class="table table-borderless table-hover">
                      <thead >
                        <tr className="justify-content-between">
                            <th className='bg-dark text-white 'style={{fontSize:'10px'}} scope="col">BOOKING ID</th>
                            <th  className='bg-dark text-white' style={{fontSize:'10px'}} scope="col">NUMBER OF DAYS</th>
                            <th  className='bg-dark text-white' style={{fontSize:'10px'}} scope="col">STATUS</th>
                            <th  className='bg-dark text-white' style={{fontSize:'10px'}} scope="col">BOOKED ON</th>

                        </tr>

                     </thead>

                    <tbody>

                          { myreservations.length <  1 ? (<h6 className= 'mt-2 text-muted'>No Reservations Available</h6>) : (  
                              
                              myreservations.map((myreservation, index)=> (
                                  <MyReservationList index={index} myreservation={myreservation} />
                              ))
                              
                            ) 
                          }
                      </tbody>
                    </table>
              </div>
              <br/>
              <br/><br/><br/><br/><br/><br/><br/><br/>
              <br/><br/><br/><br/>
              <br/><br/><br/><br/><br/><br/>
          
        </div> 

         {/* modal start */}

  <div className="modal fade " id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel1" aria-hidden="true">
  <div className="modal-dialog ">
    <div className="modal-content bg-light border border-dark border-5">
      <div className="modal-header">
        {/* <h1 className="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
         <button type="button"  style={{visibility:"hidden"}} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
         {myreservations.length < 1 ? (<>
          <div className="mb-5 text-center mt-2 fs-5">
             Your do not have any active booking to cancel, Please click
             <a href="/booking"> here</a> to create a reservation
          </div>
         </>): (<>
           <div className="mb-5 text-center mt-2 fs-5">
             Your Booking could not be cancel, Kindly reach to our customer care 
             by clicking <a href="/contact"> here </a> or call 0904454443.. immediately 
          </div> 
          </>)}
         
      </div>
      <div className="modal-footer">

        {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
      
         {/* <button type="button" 
        className=" m-auto text-dark fs-4 "
        
        style={{
          backgroundColor: "white",
          boxShadow: "4px 4px 2px  black",
        }}
        >verify KYC1</button> */}
       
       
      </div>
    </div>
  </div>
</div>

{/* modal ends */}
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
    </>
        
        
     )
    ;
}

export default Reservations
;