import './styles.css'

function ImageGrid() {
    return (
      <div className='container-fluid'>
            
            <div class="row">
            <div class="column">
            <img src="/INTERIOR PICTURES/INT-35.JPG"/>
            </div>

            <div class="column">
            <img src="/INTERIOR PICTURES/INT-28.JPG"/>
            </div>

            <div class="column">
            <img src="/INTERIOR PICTURES/INT-32.JPG"/>
            </div>

            <div class="column">
            <img src="/INTERIOR PICTURES/INT-19.JPG"/>
            </div>
            </div>
      </div>
 
      );
}

export default ImageGrid;