import { FaFacebook, FaInstagram, FaTwitter} from "react-icons/fa"
import { FaPhoneVolume } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

function Footer() {
    return ( 
        
        <div className="container-fluid bg-dark text-white">
        {/* <h3 className="text-white">Footer</h3> */}
         <div className="row">
         <div className="col-sm-12 col-md-3 col-lg-3">
         <div className="">
        
                    <br/>
                      <p className="fw-bold fs-1 font-monospace">AludysHomes
                      </p>
                      <p className="fs-6">
                         Stylish and contemporary group of hotels offering comfort with luxuries and world class amenities.
                      </p>
                  </div>

           
             </div>
              <div className="col-sm-12 col-md-3 col-lg-3">
                  <div className="">
              
                  <br/>
                    <p className="fw-bold fs-1 font-monospace">
                        Connect With Us
                      </p>
                      <p className="fs-6">
                      31b and 31c OLAIDE Adeyeye Street, Millenium Estate, Gbagada
                      </p>
                      <p className="fs-6 mt-3">
                       <FaPhoneVolume /> +234 803 455 0997, +234 701 275 9999     
                    </p>
                   
                    <p className="fs-6 mt-3">
                      <MdEmail /> Aludyshomes@gmail.com, info@aludyshomes.com   
                    </p>
                    <p className="fs-6 mt-3">
                    <span className=""><a className="text-decoration-none text-white fs-4" href="https://www.facebook.com/profile.php?id=100093187885683&mibextid=ZbWKwL"><FaFacebook/> </a> </span>  
                    <span className=""> <a className="text-decoration-none text-white fs-4"  href="https://www.instagram.com/aludyshomes?igsh=NzZmc3N0M2N1MXAz"> <FaInstagram/> </a></span>  

                    {/* <span className=""> <a className="text-decoration-none text-white fs-4"  href="#"> <FaTwitter/> </a></span>   */}

                    </p>
                   
                  </div>
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3">
              <div className="">
                     <br/>
             
                      <p className="fw-bold fs-1 font-monospace">Contact Us
                      </p>
                      <br/>
                    
                      <p className="fs-6">
                             * Secure Gated Estate
                        </p>
                        <p className="fs-6">
                             * 25mins from International Airport
                        </p>
                        <p className="fs-6">
                             * 20mins from local Airport
                        </p>
                        <p className="fs-6">
                             * 25mins to Ikoyi via Gbagada Oworosoki Expressway/Third Mainland 
                             bridge
                        </p>
                        <p className="fs-6">
                             * 20mins to Ikeja via Lagos Ikorodu Express Way
                        </p>
                        <p className="fs-6">
                             * Maryland Mall/Cinema/Restaurant 10mins way
                        </p>
                       
                  </div>
              </div>
              <div className="col-sm-12 col-md-3 col-lg-3">
              <div className="">
                      <br/>
              
                      <p className="fw-bold fs-1 font-monospace">Customer Help
                      </p>
                      <p className="fs-6">
                        <a className="text-decoration-none text-white" href="/contact">Guest Support</a>
                      </p>
                      <p className="fs-6 mt-3">
                         <a className="text-decoration-none text-white" href="/contact">Guest Feedback</a>
                      </p>
                  </div>
              </div>

            </div>  
     <div className="text-center fs-6">
     <br/>
     <br/>
     Copyright © 2024 Aludyshomes | Powered by Aludyshomes
     <br/>
     <br/>
    </div>
</div>
     );
}

export default Footer;