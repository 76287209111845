import { useContext, useState } from "react";
import { UserContext } from "../Context/Authcontext";
import ApiServices from "../Services/ApiServices";
import { constants } from "./Utils/Constants";

function Navbar({logoURL}) {
  const user = useContext(UserContext);
  const [opendashboard, setopendashboard]= useState(false)
  
  const LogOut = () => {
    ApiServices.handleLogout();
    window.location.replace(`${constants.clientURL}`);
  };

  return (
    <>
 <nav class="navbar navbar-dark bg-dark fixed-top border  border-warning border-5 border-start-0 border-end-0 border-top-0">
  <div class="container-fluid">
    <a class="navbar-brand" href="/">
    <img
              src=  {logoURL ? `${logoURL}/aludyslogo.png`: "aludyslogo.png"}
              // src=  {logoURL ? `${logoURL}/`: }
           
              alt="Logo"
              width="150"
              height="60"
              class="d-inline-block align-text-top"
            />
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasDarkNavbarLabel">
             <a class="navbar-brand" href="/">
             <img
              src=  {logoURL ? `${logoURL}/aludyslogo.png`: "aludyslogo.png"}
              alt="Logo"
              width="150"
              height="60"
              class="d-inline-block align-text-top"
            />
              </a>
          </h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>

      <div class="offcanvas-body">

        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
          {/* {user && 
           <>
            <li class="nav-item">
              <a class="nav-link fs-5 text-white" href="/profile">
                Profile
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link fs-5 text-white"
                href="/Reservations"
              >
                Reservations
              </a>
            </li>
           </>
          }   */}
            

            <li class="nav-item">
                  <a class="nav-link fs-5 text-white" href="https://aludys.com/shop">
                    Book Reservation
                  </a>
                </li>
                
                {/* <a
                  class="nav-link fs-5 active text-white"
                  aria-current="page"
                  href="/about"
                >
                  About
                </a> */}
                <li class="nav-item">
                  <a class="nav-link fs-5 text-white" href="/gallery">
                    Gallery
                  </a>
                </li> 
                <li class="nav-item">
                  <a class="nav-link fs-5 text-white" href="/contact">
                    Contact
                  </a>
                </li>
                {/* <li class="nav-item">
                  {user ? (
                    <a
                      onClick={() => LogOut()}
                      class="fs-5 text-white "
                      style={{ backgroundColor: "#1d1d1d" }}
                    >
                      <button className="rounded-3 w-25">logout</button>
                    </a>
                  ) : (
                    
                            <a href="/login" className="text-decoration-none p-1 fs-3 bg-light text-dark ">Login</a>             
                  
                  )}
                </li> */}
        </ul>
       
           
      </div>
    </div>
  </div>
</nav>
    </>
  );
}

export default Navbar;
