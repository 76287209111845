import { Navigate, useParams } from "react-router-dom";
import { useBookingProfile } from "../Hooks/useBookingProfile";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../Context/Authcontext";
import Navbar from "../Components/Navbar";
import ApiServices from "../Services/ApiServices";
import Checkout from "./Checkout";
import { displayAlert } from "../Components/Utils/Appfunctions";


function ReservationInfo() {

    const user = useContext(UserContext)
    const bookingId = useParams()
    //  console.log(bookingId);
    const {bookingInfo} = useBookingProfile(bookingId)
    const [amountperday, setAmountperday]=useState(50000)

    const [alertmessage, setAlertmessage] = useState(false);
    const [message, setMessage] = useState("");
    const [isError, setIsError] = useState(false);

     const [checkedOutBooking, setCheckOutBooking ]= useState(false)
  

     const modalRef = useRef()

    const handleCheckout = useCallback(async(bookingId)=> {
     modalRef.current.click()
        console.log("bookingIDcheckout", bookingId);
            
        try {
            const response = await ApiServices.getRequestNoAuth(`/controllers/checkoutbooking.php?bookingId=${bookingInfo.bookingId}&email=${user.email}`);  

            console.log("cjkds",response);
            if (response) {
              const { success, message } = response;
              
              if (success) {
                setAlertmessage(true)
              
                setMessage(message);
                setIsError(false);
                setTimeout(() => setAlertmessage(false), 3000);
                window.location.reload()
              } 
            else {
                setAlertmessage(true)
                setMessage(message);
                setIsError(true);
                setTimeout(() => setAlertmessage(false), 3000);

              }
            } else {
              setMessage("Unexpected error occurred.");
              setIsError(true);
              setTimeout(() => setAlertmessage(false), 3000);
            }
          } catch (error) {
            console.error("Login error:", error);
            setMessage("Network error occurred.");
            setIsError(true);
            setTimeout(() => setAlertmessage(false), 3000);
          }
    })

    if (!user){
    return <Navigate to="/login" replace/>
    }
  
    return ( 
        <>
        <Navbar logoURL={'../'}/>
        {displayAlert({ alertmessage, isError, message })}
        <div className="container-fluid" style={{backgroundColor:"black"}}>
          <div className="row">
            <div className="col-lg-3"></div>
            <div className="col-lg-6 vh-100">
            <br />
            <br />  <br />
            <br />
              <div className="p-2 bg-light shadow mt-5 ">

                <h4 className="m-3 p-2 text-dark">Booking Details</h4>

                {bookingInfo.status === "BOOKED" && 
                <span className="float-end mb-2">
                   
                  <button  class="btn btn-warning border-0" type="button"
                     data-bs-toggle="modal" data-bs-target="#exampleModal">
                       checkout
                    </button>
                  </span>
                }
                        <table class="table table-borderless">
                            <thead>
                            </thead>
                            <tbody>

                                <tr>
                                <th scope="row"></th>
                                <td>
                                    <span className="fw-bold fs-6"> Name </span>                
                                </td>
                                <td></td>
                                <td>       
                                    <span className="ms-3 fs-6 ">{user.fullname}</span>
                                </td>
                                </tr>

                                <tr>
                                <th scope="row"></th>
                                <td>
                                    <span className="fw-bold fs-6"> Email </span>                
                                </td>
                                <td></td>
                                <td>       
                                    <span className="ms-3 fs-6 ">{user.email}</span>
                                </td>
                                </tr>

                                {bookingInfo.phonenumber && 
                                
                                <tr>
                                <th scope="row"></th>
                                <td>
                                    <span className="fw-bold fs-6"> Phone </span>                
                                </td>
                                <td></td>
                                <td>       
                                    <span className="ms-3 fs-6 ">{bookingInfo.phonenumber}</span>
                                </td>
                                </tr>
                                }
                                


                                <tr>
                                <th scope="row"></th>
                                <td>
                                    <span className="fw-bold fs-6">Checkin </span>                
                                </td>
                                <td></td>
                                <td>       
                                  {/* styles in the cart page styles */}
                                    <span className="ms-3 cartspan">{bookingInfo.checkIn}</span>
                                </td>
                                </tr>

                                <tr>
                                <th scope="row"></th>
                                <td>
                                    <span className="fw-bold" > Checkout </span>                
                                </td>
                                <td></td>
                                <td>       
                                    <span className="ms-3 cartspan">{bookingInfo.checkOut}</span>
                                </td>
                                </tr>

                                <tr>
                                <th scope="row"></th>
                                <td>
                                    <span className="fw-bold fs-6"> Nights</span>                
                                </td>
                                <td></td>
                                <td>       
                                    <span className="ms-3 fs-6 ">{bookingInfo.numberOfDays}</span>
                                </td>
                                </tr>

                                {bookingInfo.adults && 
                                <tr>
                                <th scope="row"></th>
                                <td>
                                    <span className="fw-bold fs-6"> Adults </span>                
                                </td>
                                <td></td>
                                <td>       
                                    <span className="ms-3 fs-6 ">{bookingInfo.adults}</span>
                                </td>
                                </tr>
                                }
                            
                            
                            {bookingInfo.children && 
                                <tr>
                                <th scope="row"></th>
                                <td>
                                    <span className="fw-bold fs-6"> children </span>                
                                </td>
                                <td></td>
                                <td>       
                                    <span className="ms-3 fs-6 ">{bookingInfo.children}</span>
                                </td>
                                </tr>
                            }
                            

                                <tr>
                                <th scope="row"></th>
                                <td>
                                    <span className="fw-bold fs-6"> Amount Per day </span>                
                                </td>
                                <td></td>
                                <td>       
                                    <span className="ms-3 fs-6 ">{amountperday}</span>
                                </td>
                                </tr>

                                <tr>
                                <th scope="row"></th>
                                <td>
                                    <span className="fw-bold fs-6"> Total Amount </span>                
                                </td>
                                <td></td>
                                <td>       
                                    <span className="ms-3 fs-6 ">{bookingInfo.amount}</span>
                                </td>
                                </tr>

                                <tr>
                                <th scope="row"></th>
                                <td>
                                    <span className="fw-bold fs-6"> Invoice ID </span>                
                                </td>
                                <td></td>
                                <td>       
                                    <span className="ms-3 cartspan">{bookingInfo.invoiceId}</span>
                                </td>
                                </tr>

                                <tr>
                                <th scope="row"></th>
                                <td>
                                    <span className="fw-bold fs-6"> Booking ID </span>                
                                </td>
                                <td></td>
                                <td>       
                                    <span className="ms-3 cartspan ">{bookingInfo.bookingId}</span>
                                </td>
                                </tr>


                                <tr>
                                <th scope="row"></th>
                                <td>
                                    <span className="fw-bold fs-6"> Booking Status </span>                
                                </td>
                                <td></td>
                                <td>       
                                    <span className="ms-3 fs-6 ">{bookingInfo.status}</span>
                                </td>
                                </tr>


                            </tbody>
                            </table>

                </div>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        

         {/* modal starts */}
         <div className="modal fade " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog ">
    <div className="modal-content bg-light border border-dark border-5">
      <div className="modal-header">
        {/* <h1 className="modal-title fs-5" id="staticBackdropLabel">Modal title</h1> */}
         <button type="button" ref={modalRef} style={{visibility:"hidden"}} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
         <div className="mb-5 text-center mt-2 fs-6">
             You will be checked out of your reservation, Please confirm to continue
          </div> 
      </div>
      <div className="modal-footer">
        {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
      
         <button type="button" 
        className=" m-auto text-dark fs-4 "
        onClick={()=>handleCheckout(bookingId.bookingId)}
        style={{
          backgroundColor: "white",
          boxShadow: "4px 4px 2px  black",
        }}
        >confirm</button>
       
       
      </div>
    </div>
  </div>
</div>
        {/* modal ends */}
      </>
     );
}

export default ReservationInfo;