import { useContext } from "react";
import { UserContext } from "../Context/Authcontext";
import AdminLoginForm from "./AdminLoginForm";
import Navbar from "../Components/Navbar";

function AdminHome() {

    const user = useContext(UserContext)


    return ( 
        <>
         {user.fullname === "Admin" ? (
          <> 

          <Navbar />
        <div className="container-fluid">

           <div className="row">
              <div className="col-2 ">
                 <div className="d-block  mt-4 p-2 bg-white vh-100">
                     <a className ='text-decoration-none text-black' href="/reservations"><span className="m-2 fs-5">Reservations</span> </a> 
                    <br />
                     <a className="text-decoration-none"></a> <span className="m-2 fs-5">Profile </span>
                    <br />
                     <a className ='text-decoration-none text-black' >  <span className="m-2 fs-5">Settings </span></a>
                 </div>
              </div>
              <div className="col-8">
                  {/*  */}
                  <div className="mt-4" style={{backgroundColor:"black"}}>
                 <div className="text-dark bg-light mt-4">
                <h3 className="p-2">Reservations</h3>
                  
                <div class=" mt-4 table-responsive p-2 ">
                <table class="table table-light table-hover">
                    
                    <thead >
                        <tr className="justify-content-between">
                            <th className='bg-dark text-white 'style={{fontSize:'10px'}} scope="col">TRANSACTION ID</th>
                            <th className='bg-dark text-white ' style={{fontSize:'10px'}} scope="col">DATE</th>
                            <th className='bg-dark text-white ' style={{fontSize:'10px'}} scope="col">TAG</th>
                            <th  className='bg-dark text-white' style={{fontSize:'10px'}} scope="col">AMOUNT</th>
                            <th  className='bg-dark text-white' style={{fontSize:'10px'}} scope="col">STATUS</th>
                        </tr>
                    </thead>

                    <tbody>
                    <tr className="justify-content-between">
                            <th className='bg-dark text-white 'style={{fontSize:'10px'}} scope="col"></th>
                            <th className='bg-dark text-white ' style={{fontSize:'10px'}} scope="col"></th>
                            <th className='bg-dark text-white ' style={{fontSize:'10px'}} scope="col"></th>
                            <th  className='bg-dark text-white' style={{fontSize:'10px'}} scope="col"></th>
                            <th  className='bg-dark text-white' style={{fontSize:'10px'}} scope="col"></th>
                        </tr>
                      {/* { userTransactions.length <  1 ? (<h6 className= 'mt-2 text-muted'>No Transactions Available</h6>) : (  
                         
                         userTransactions.map((transaction, index)=> (
                            <TransactionList index={index} transaction={transaction}/>
                         ))
                        
                       ) 
                     } */}
                           
                    </tbody>
                </table>
              </div>
            </div>
        </div>
                  {/*  */}
              </div>
           </div>

        </div>
          </>
         ): (
          <> 
            <AdminLoginForm />
          </>
         )}
        </>
     );
}

export default AdminHome;