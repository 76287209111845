import './overlay.css'

function ImageOverlay({imgsrc, title}) {

    return ( 
        <div class="contain">
        <img src={imgsrc} alt="Avatar" class="img-fluid" 
        //    height={400}
        //         width={400}
                />
        <div class="overlay">{title}</div>
      </div>
      );
}

export default ImageOverlay;