import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

function AliceTwo() {
  const responsive = {
    0: { items: 1 },
    512: { items: 3 },
    // 1024: { items: 3 }
  };
  const items = [
    <div
      className="item ms-1 me-1"
      data-value="1"
    >
      <img
        className="border border-warning border-5"
        src="/INTERIOR PICTURES/DSC_5002.jpg"
        id="herocarousel1"
      />
    </div>,

    <div
      className="item ms-1 me-1"
      data-value="2"
    >
      <img
        className="border border-warning border-5"
        src="/INTERIOR PICTURES/INT-16.JPG"
        id="herocarousel1"
      />
    </div>,

    <div
      className="item ms-1 me-1"
      data-value="3"
    >
      <img
        className="border border-warning border-5"
        src="/INTERIOR PICTURES/INT-13.JPG "
        id="herocarousel1"
      />
    </div>,

    <div
      className="item ms-1 me-1"
      data-value="4"
    >
      <img
        className="border border-warning border-5"
        src="/INTERIOR PICTURES/DSC_5239.jpg"
        id="herocarousel1"
      />
    </div>,

    <div
      className="item ms-1 me-1"
      data-value="5"
    >
      <img
        src="/INTERIOR PICTURES/INT-12.JPG"
        className="border border-warning border-5"
        id="herocarousel1"
      />
    </div>,

    <div
      className="item ms-1 me-1"
      data-value="5"
    >
      <img
        src=" /INTERIOR PICTURES/DSC_5242.jpg"
        className="border border-warning border-5"
        id="herocarousel1"
      />
    </div>,

    <div
      className="item ms-1 me-1"
      data-value="5"
    >
      <img
        src="/INTERIOR PICTURES/INT-6.JPG"
        className="border border-warning border-5"
        id="herocarousel1"
      />
    </div>,

    <div
      className="item ms-1 me-1"
      data-value="5"
    >
      <img
        src="/INTERIOR PICTURES/DSC_5285.jpg"
        className="border border-warning border-5"
        id="herocarousel1"
      />
    </div>,

    <div
      className="item ms-1 me-1"
      data-value="5"
    >
      <img
        src="/INTERIOR PICTURES/DSC_5237.jpg"
        className="border border-warning border-5"
        id="herocarousel1"
      />
    </div>,

    <div
      className="item ms-1 me-1"
      data-value="5"
    >
      <img
        src="/INTERIOR PICTURES/DSC_5273.jpg"
        className="border border-warning border-5"
        id="herocarousel1"
      />
    </div>,

    <div
      className="item ms-1 me-1"
      data-value="5"
    >
      <img
        src="/INTERIOR PICTURES/DSC_5074.jpg"
        className="border border-warning border-5"
        id="herocarousel1"
      />
    </div>,

    <div
      className="item ms-1 me-1"
      data-value="5"
    >
      <img
        src="/INTERIOR PICTURES/DSC_5071.jpg"
        className="border border-warning border-5"
        id="herocarousel1"
      />
    </div>,

    <div
      className="item ms-1 me-1"
      data-value="5"
    >
      <img
        src="/INTERIOR PICTURES/DSC_5062.jpg"
        className="border border-warning border-5"
        id="herocarousel1"
      />
    </div>,

    <div
      className="item ms-1 me-1"
      data-value="5"
    >
      <img
        src="/INTERIOR PICTURES/DSC_5011.jpg"
        className="border border-warning border-5"
        id="herocarousel1"
      />
    </div>,

    <div
      className="item ms-1 me-1"
      data-value="5"
    >
      <img
        src="/INTERIOR PICTURES/DSC_5298.jpg"
        className="border border-warning border-5"
        id="herocarousel1"
      />
    </div>,

    <div
      className="item ms-1 me-1"
      data-value="5"
    >
      <img
        src="/INTERIOR PICTURES/DSC_5024.jpg"
        className="border border-warning border-5"
        id="herocarousel1"
      />
    </div>,

    <div
      className="item ms-1 me-1"
      data-value="5"
    >
      <img
        src="/INTERIOR PICTURES/DSC_5034.jpg"
        className="border border-warning border-5"
        id="herocarousel1"
      />
    </div>,
  ];


  return (
    <AliceCarousel
      autoPlay
      // autoPlayControls
      // paddingLeft={""}
      // paddingRight={""}

      autoPlayStrategy="none"
      autoPlayInterval={500}
      animationDuration={500}
      animationType="fadeout"
      infinite
      touchTracking={false}
      disableDotsControls
      disableButtonsControls
      items={items}
      responsive={responsive}
    />
  );
}

export default AliceTwo;
