import moment from "moment";
import { useCallback, useContext, useRef, useState } from "react";
import ApiServices from "../Services/ApiServices";
import { UserContext } from "../Context/Authcontext";
import { displayAlert } from "./Utils/Appfunctions";

function MyReservationList({myreservation, index}) {

    const { bookingId, status, numberOfDays, date_created} = myreservation;
    // console.log("receved",bookingId,invoiceId, checkIn,checkOut,adults,children, payment_status,payment_method,amount,isActive,hasCheckedout,isCanceled, date_created );

    
    return ( <>

        <tr  >
    
            <td scope="row">
            <a href={`/reservation/${bookingId}`} key={index} className="text-decoration-none fw-bold">
               {bookingId} 
           </a> 
              
              
              </td>
            <td>
             
              <a href={`/reservation/${bookingId}`} key={index} className="text-decoration-none text-dark ">
              {numberOfDays} 
           </a>   
            </td>      
            <td>{status}</td>
            <td>{`${moment(date_created).format('YYYY-MM-DD')}`}</td>
            {/* <td>
               <button  class="btn btn-warning" type="button"
               data-bs-toggle="modal" data-bs-target="#exampleModal">
                       checkout
                </button>
            </td> */}
            {/* <td>
               <button class="btn btn-danger " type="button" >
                    cancel
                </button> 
            </td> */}     
             
        </tr>
      
       

       
                     
    </>  );
}

export default MyReservationList;