import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';


function AliceOne() {

    const responsive = {
        0:{items:1},
        512:{items:3},
        // 1024: { items: 3 }

    }
    const items = [
        <div className="item ms-1 me-1" style={{marginLeft:"1rem", marginRight:"1rem"}} data-value="1">
        <img className='border border-warning border-5'src="/INTERIOR PICTURES/DSC_5079.jpg" id='herocarousel1'/>
        </div>,

        <div className="item ms-1 me-1" style={{marginLeft:"1rem", marginRight:"1rem"}} data-value="2">
         <img className='border border-warning border-5' src="INTERIOR PICTURES/DSC_5020.jpg" id='herocarousel1' />
        </div>,

        <div className="item ms-1 me-1" style={{marginLeft:"1rem", marginRight:"1rem"}} data-value="3">
        <img  className='border border-warning border-5' src="/INTERIOR PICTURES/DSC_4994.jpg " id='herocarousel1' />
        </div>,

        <div className="item ms-1 me-1" style={{marginLeft:"1rem", marginRight:"1rem"}}  data-value="4">
          <img  className='border border-warning border-5' src="/INTERIOR PICTURES/DSC_5239.jpg"  id='herocarousel1'/>
        </div>,

        <div className="item ms-1 me-1" style={{marginLeft:"1rem", marginRight:"1rem"}} data-value="5">
        <img src="/INTERIOR PICTURES/DSC_5156.jpg" className='border border-warning border-5' id='herocarousel1'/>
        </div>,

        <div className="item ms-1 me-1" style={{marginLeft:"1rem", marginRight:"1rem"}} data-value="5">
        <img src="    /INTERIOR PICTURES/DSC_5242.jpg" className='border border-warning border-5' id='herocarousel1'/>
        </div>,

        <div className="item ms-1 me-1" style={{marginLeft:"1rem", marginRight:"1rem"}} data-value="5">
        <img src="/INTERIOR PICTURES/DSC_5285.jpg" className='border border-warning border-5' id='herocarousel1'/>
        </div>,

        <div className="item ms-1 me-1" style={{marginLeft:"1rem", marginRight:"1rem"}} data-value="5">
        <img src="/INTERIOR PICTURES/DSC_5287.jpg" className='border border-warning border-5' id='herocarousel1'/>
        </div>,

        <div className="item ms-1 me-1" style={{marginLeft:"1rem", marginRight:"1rem"}} data-value="5">
        <img src="/INTERIOR PICTURES/DSC_5244.jpg" className='border border-warning border-5' id='herocarousel1'/>
        </div>,
        
        <div className="item ms-1 me-1" style={{marginLeft:"1rem", marginRight:"1rem"}} data-value="5">
        <img src="/INTERIOR PICTURES/DSC_5303.jpg" className='border border-warning border-5' id='herocarousel1'/>
        </div>,
        
        <div className="item ms-1 me-1" style={{marginLeft:"1rem", marginRight:"1rem"}} data-value="5">
        <img src="/INTERIOR PICTURES/DSC_5308.jpg" className='border border-warning border-5' id='herocarousel1'/>
        </div>,

<div className="item ms-1 me-1" style={{marginLeft:"1rem", marginRight:"1rem"}} data-value="5">
<img src="/INTERIOR PICTURES/DSC_5281.jpg" className='border border-warning border-5' id='herocarousel1'/>
</div>,

<div className="item ms-1 me-1" style={{marginLeft:"1rem", marginRight:"1rem"}} data-value="5">
<img src="/INTERIOR PICTURES/DSC_5296.jpg" className='border border-warning border-5' id='herocarousel1'/>
</div>,

<div className="item ms-1 me-1" style={{marginLeft:"1rem", marginRight:"1rem"}} data-value="5">
<img src="/INTERIOR PICTURES/DSC_5307.jpg" className='border border-warning border-5' id='herocarousel1'/>
</div>,

<div className="item ms-1 me-1" style={{marginLeft:"1rem", marginRight:"1rem"}} data-value="5">
<img src="/INTERIOR PICTURES/DSC_5298.jpg" className='border border-warning border-5' id='herocarousel1'/>
</div>,

<div className="item ms-1 me-1" style={{marginLeft:"1rem", marginRight:"1rem"}} data-value="5">
<img src="/INTERIOR PICTURES/DSC_5313.jpg" className='border border-warning border-5' id='herocarousel1'/>
</div>,

<div className="item ms-1 me-1" style={{marginLeft:"1rem", marginRight:"1rem"}} data-value="5">
<img src="/INTERIOR PICTURES/INT-3.JPG" className='border border-warning border-5' id='herocarousel1'/>
</div>,
    ];
    return (  
        <AliceCarousel
        autoPlay
        // autoPlayControls
        // paddingLeft={""}
        // paddingRight={""}  
            
        autoPlayStrategy="none"
        autoPlayInterval={500}
        animationDuration={500}
        animationType="fadeout"
        infinite
        touchTracking={false}
        disableDotsControls
        disableButtonsControls
        items={items}
        responsive={responsive}
    />
    );
}

export default AliceOne;

