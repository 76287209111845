import { FaPhone } from "react-icons/fa6";
import Navbar from "../Components/Navbar";
import "../Components/styles.css";
import Footer from "../Components/Footer";
import { useSpring, animated, useChain } from "react-spring";
import { useEffect, useRef, useState } from "react";
import AniamteWelcome from "../Components/AnimateWelcom";
import { FaArrowAltCircleRight } from "react-icons/fa";
import Testimonials from "../Components/Testimonials";
import AliceOne from "../Components/AliceOne";
import ImageOverlay from "../Components/ImageOverlay";
// import AnimatedTextSlides from "../Components/Testimonials";

function Home() {
  //   const props = useSpring({
  //     from: { transform: 'translateX(-100%)' },
  //     to: { transform: 'translateX(0%)' },
  //     config: { duration: 1000 },
  //     reset: true, // Reset animation on each repeat
  //     onRest: () => setTimeout(() => props.start({}), 2000) // Restart animation every 2 seconds
  //   });

  //   const props = useSpring({
  //     from: { transform: 'translateX(-100%)' },
  //     to: { transform: 'translateX(0%)' },
  //     config: { duration: 1000 },
  //     reset: true // Reset animation on each repeat
  //   });

  const chainRef = useRef();

  //   useChain([chainRef], [0, 0]);

  //   const props = useSpring({
  //     to: { transform: 'translateX(0%)' },
  //     from: { transform: 'translateX(-100%)' },
  //     config: { duration: 1000 }
  //   });

  const [toggle, setToggle] = useState(false);

  const props = useSpring({
    opacity: toggle ? 1 : 0,
    config: { duration: 1000 },
    reset: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setToggle(!toggle);
    }, 2000);

    return () => clearInterval(interval);
  }, [toggle]);

  return (
    <div style={{ backroundColor: "black" }}>
      <Navbar />
      <div
        id="carouselExampleDark"
        class="carousel carousel-dark slide"
        data-bs-ride="carousel"
      >
        <div class="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="0"
            class="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="3"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="4"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleDark"
            data-bs-slide-to="5"
            aria-label="Slide 3"
          ></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active" data-bs-interval="10000">
            <img
              src="/INTERIOR PICTURES/INT(1).jpg  "
              id="herocarousel"
              class="d-block w-100"
              alt="..."
            />
            <div class="carousel-caption d-none d-md-block">
              <h1 className="text-dark row5p1">
                <AniamteWelcome />
              </h1>
              <p>
                <a
                  href="https://aludys.com/shop"
                  className="text-decoration-none p-2 bg-dark text-light row5btn"
                >
                  Book Now
                </a>
              </p>
            </div>
          </div>

          <div class="carousel-item" data-bs-interval="2000">
            <img
              src="/INTERIOR PICTURES/INT-22.JPG"
              id="herocarousel"
              class="d-block w-100"
              alt="..."
            />
            <div class="carousel-caption d-none d-md-block">
              {/* <h5>Second slide label</h5>
                        <p>Some representative placeholder content for the second slide.</p> */}
            </div>
          </div>

          <div class="carousel-item">
            <img
              src="/INTERIOR PICTURES/DSC_5027.jpg"
              id="herocarousel"
              class="d-block w-100"
              alt="..."
            />
            <div class="carousel-caption d-none d-md-block">
              {/* <h5>Third slide label</h5>
                        <p>Some representative placeholder content for the third slide.</p> */}
            </div>
          </div>

          <div class="carousel-item">
            <img
              src="/INTERIOR PICTURES/DSC_5082.jpg"
              id="herocarousel"
              class="d-block w-100"
              alt="..."
            />
            <div class="carousel-caption d-none d-md-block">
              {/* <h5>Third slide label</h5>
                        <p>Some representative placeholder content for the third slide.</p> */}
            </div>
          </div>
          <div class="carousel-item">
            <img
              src="/INTERIOR PICTURES/DSC_5152.jpg"
              id="herocarousel"
              class="d-block w-100"
              alt="..."
            />
            <div class="carousel-caption d-none d-md-block">
              {/* <h5>Third slide label</h5>
                        <p>Some representative placeholder content for the third slide.</p> */}
            </div>
          </div>
          <div class="carousel-item">
            <img
              src="/INTERIOR PICTURES/INT-19.JPG"
              id="herocarousel"
              class="d-block w-100"
              alt="..."
            />
            <div class="carousel-caption d-none d-md-block">
              {/* <h5>Third slide label</h5>
                        <p>Some representative placeholder content for the third slide.</p> */}
            </div>
          </div>
        </div>

        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleDark"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>

      <br />
      <br />

      <div className="container-fluid mt-3" style={{  backgroundImage: "linear-gradient(to right, red,orange,yellow,green,blue,indigo,violet)"
}}>
        <div className="row">
          <div className="col-sm-12 col-md-1 col-lg-2"></div>
          <div className="col-sm-12 col-md-10 col-lg-8">
            <div className="w-100 h-100 text-center">
              <h1
                className=" row2h1 text-monospace  mt-4 mb-1"
                
              >
                What you should expect
              </h1>
              <h3 className=" row2h3 text-monospace mb-5">
                Experience the best comfort you deserve
              </h3>

              <iframe
                width="100%"
                height="400"
                src="https://www.youtube.com/embed/eEGVm6vLmaQ?autoplay=1&mute=0"
              ></iframe>
            </div>

            {/* <video controls className="w-100" autoPlay src="https://www.youtube.com/watch?v=LXb3EKWsInQ" >
                            
                            </video> */}
          </div>
          <div className="col-sm-12 col-md-1 col-lg-2"></div>
        </div>
        <br />
        <br />
      </div>
      <div></div>
      <br />

      {/* <div className="container-fluid">
                    <Carousels/>
                </div> */}

      <div className="container-fluid mt-5">
        <AliceOne />
      </div>

      <div className="container-fluid mt-5">
        <div className="row mb-5">
          <div className="col-sm-12 col-md-6 col-lg-6 ">
            <div className="row-four">
              <span id="hideeltonsmscreen">
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </span>
              <br /> <br /> <br /> <br /> <br />
              <br /> <br /> <br /> <br /> <br />
              <br /> <br /> <br /> <br /> <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              {/* <img src="/INTERIOR PICTURES\DSC_4994.jpg"className=" row5img" /> */}
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6">
            <div className="text-white m-5">
              <p className="row5p1">
                {" "}
                Aludys<span className="bg-warning">Homes</span>{" "}
              </p>
              <animated.p ref={chainRef} style={props} className=" row5p2">
                Looking for a relaxation, refreshing and rejuvenating
                experience? At Aludyshomes we offer all under one roof.
              </animated.p>
              <a
                href="https://aludys.com/shop"
                className="text-decoration-none p-2 bg-white text-dark row5btn"
              >
                Book Reservation Now
              </a>
            </div>
          </div>
        </div>
        {/*  */}
      </div>

      <section>
        {/* Amnitiesv Card */}
        <div className=" container-fluid">
          <div className="text-white ">
            <h1 className="fw-bold row6h1">Amenities & Facilities​</h1>
            <p className="fs-5">
              Seeking a complete experience of relaxation, refreshment, and
              rejuvenation, we provide it all under one roof.
            </p>
          </div>

          <div class="row ">

            <div class="col-sm-12 col-md-6 col-lg-6">
                <br/>
                {/* <img src="/INTERIOR PICTURES/INT(1).jpg" class="img-fluid" alt="..."></img> */}
                <ImageOverlay imgsrc={"/INTERIOR PICTURES/INT(1).jpg"} title={'Executive Bedrooms'} />
              {/* <img
                src="/INTERIOR PICTURES/INT(1).jpg"
                class="card-img-top"
                height={400}
                width={400}
                alt="..."
              />
              <div class="">
                <h5 class="text-white mt-3 fs-2 mb-2">Executive Bedrooms</h5>
              </div> */}
            </div>

            <div class=" col-sm-12 col-md-6 col-lg-6 ">
              <br/>
            {/* <img src="/INTERIOR PICTURES/INT-12.JPG" class="img-fluid" alt="..."></img> */}

            <ImageOverlay imgsrc={"/INTERIOR PICTURES/INT-12.JPG"} title={'Modern Kitchen'} />

              {/* <img
                src="/INTERIOR PICTURES/INT-12.JPG"
                class="card-img-top"
                height={400}
                width={400}
                alt="..."
              />
              <div class="card-body">
                <h5 class="text-white mt-3 fs-2 mb-2">Modern Kitchen</h5>
              </div> */}
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6 ">
            <br/>
            {/* <img src="/INTERIOR PICTURES/INT-11.JPG" class="img-fluid" alt="..."></img> */}

            <ImageOverlay imgsrc={"/INTERIOR PICTURES/INT-11.JPG"} title={'Living Room'} />

              {/* <img
                src="/INTERIOR PICTURES/INT-11.JPG"
                class="card-img-top"
                height={400}
                width={400}
                alt="..."
              />
              <div class="">
                <h5 class="text-white mt-3 fs-2 mb-2">Living Room</h5>
              </div> */}
            </div>

            <div class="col-sm-12 col-md-6 col-lg-6">
            <br/>
            {/* <img src="/INTERIOR PICTURES/DSC_5251.jpg" class="img-fluid" alt="..."></img> */}

            <ImageOverlay imgsrc={"/INTERIOR PICTURES/DSC_5251.jpg"} title={'Standard Dinning Room'} />

              {/* <img
                src="/INTERIOR PICTURES/DSC_5251.jpg"
                class="card-img-top"
                height={400}
                width={400}
                alt="..."
              />
              <div class="card-body">
                <h5 class="text-white mt-3 fs-2 mb-2">Standard Dinning Room</h5>
              </div> */}
            </div>
          </div>

          <p className="fs-6">
            <a className="text-decoration-none text-warning" href="/gallery">
              view more ----
              <FaArrowAltCircleRight />
            </a>
          </p>
        </div>
      </section>

      <section className="testimonials" data-aos="fade-up">
        <div className="container-fluid text-white">
          <h1 className="fw-bold row6h1 text-center ">What Our Guest Say </h1>
          <p className="fs-5  text-center">
            We take pride in serving our guests with the best experience. Read
            what they say.
          </p>
        </div>
        <Testimonials />
      </section>

      {/* next Sectioon */}
      <div className="mt-5">
        <div className="row7">
          {/* <img src="/INTERIOR PICTURES/DSC_5011.jpg" className="row7img"/> */}
          <div className="text-center text-black">
            <br />
            <br />
            <br />
            <h1 className="fw-bold row7h1">
              Plan an Unforgettable Experience in{" "}
              <span className="text-decoration-underline">AludyHomes </span>{" "}
              Today!
            </h1>
            <br />
            <br />
            <p className="mt-5 row7p1 fs-2 ">
              {" "}
              <a className="text-decoartion-none text-black" href="https://aludys.com/shop">
                {" "}
                <button>BOOK YOUR STAY NOW</button>​
              </a>{" "}
            </p>
            <br />

            <p className=" fw-bold row7p2 bg-warning ">
              <FaPhone /> +234 803 455 0997​
            </p>
          </div>
        </div>
      </div>

      {/* footer */}

      <Footer />

      {/*  */}
    </div>
  );
}

export default Home;
