import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { signupSchema } from "../Components/FormSchema";
import ApiServices from "../Services/ApiServices";
import { displayAlert } from "../Components/Utils/Appfunctions";
import "../Components/styles.css";
import Navbar from "../Components/Navbar";

function RegisterForm() {
  const initialValues = {
    fullname: "",
    email:"",
    // phone_number:"",
    password: "",
    confirmpassword: "",
  };

  const navigate = useNavigate();

  const [alertmessage, setAlertmessage] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const onSubmit = async (values, actions) => {
   

    try {
      if (!values) return;
      console.log("submitted", values);
      const response = await ApiServices.postRequestNoAuth("/controllers/register.php", values);
      // const response = await ApiServices.postRequestNoAuth("http://localhost:5000/controllers/login.php", values);
       
      console.log("response=", response);
      if (response) {
        const { success, message } = response;
        setAlertmessage(true);
        
        if (success) {
          setMessage(message);
          setIsError(false);
          setTimeout(() => setAlertmessage(false), 2000);
          // Optionally, redirect to another page:
          navigate('/login')
        } else {
          setMessage(message);
          setIsError(true);
          setTimeout(() => setAlertmessage(false), 3000);
          actions.resetForm();
        }
      } else {
        setMessage("Unexpected error occurred.");
        setIsError(true);
        setTimeout(() => setAlertmessage(false), 3000);
        actions.resetForm();
      }
    } catch (error) {
      console.error("Login error:", error);
      setMessage("Network error occurred.");
      setIsError(true);
      setTimeout(() => setAlertmessage(false), 3000);
      actions.resetForm();
    }
  };


  
  return (
    <>
      {/*  */}
      <Navbar />
      <div className="container-fluid" style={{backgroundColor:"black"}}>
        {displayAlert({ alertmessage, isError, message })}
        <div className="row  ">
        
          <div className="col-sm-12 col-md-12 col-lg-4"></div>
          <div className="col-sm-12 col-md-12 col-lg-4 vh-100">
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
            {/* //  start */}
            <div className="p-2 bg-light shadow mt-5">
              <div className="m-3">
                <Formik
                  initialValues={initialValues}
                  validationSchema={signupSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      class="row g-3 text-center  mt-5"
                    >
                      <div className="text-center">
                      <a class="navbar-brand" href="/">
             {/* <img
              src="aludyslogo.png"
              alt="Logo"
              width="150"
              height="60"
              class="d-inline-block align-text-top"
            /> */}
              </a>
                        <h5 className="fs-3 font-monospace mt-3"> Welcome to  AludysHomes </h5>
                      </div>

                      <div class="col-md-12">
                        <label
                          for="inputCity"
                          class="form-label float-start"
                        ></label>
                        <br></br>
                        <input
                          type="text"
                          name="fullname"
                          placeholder="Fullname"
                          value={values.fullname}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          class="form-control-lg w-100  border border-dark border-3 border-end-0 border-start-0 border-top-0"
                          id="inputCity"
                        />
                        <br />
                        {errors.fullname && (
                          <small
                            style={{ fontSize: 10 }}
                            className=" text-danger float-start"
                          >
                            {errors.fullname}
                          </small>
                        )}
                      </div>

                      <div class="col-md-12">
                        <label
                          for="inputCity"
                          class="form-label float-start"
                        ></label>
                        <br></br>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          class="form-control-lg w-100 border border-dark border-3 border-end-0 border-start-0 border-top-0 "
                          id="inputCity"
                        />
                        <br />
                        {errors.email && (
                          <small
                            style={{ fontSize: 10 }}
                            className=" text-danger float-start"
                          >
                            {errors.email}
                          </small>
                        )}
                      </div>

                      {/* <div class="col-md-12">
                        <label
                          for="inputCity"
                          class="form-label float-start"
                        ></label>
                        <br></br>
                        <input
                          type="text"
                          name="phone_number"
                          placeholder="Phone Number"
                          value={values.phone_number}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          class="form-control-lg w-100 "
                          id="inputCity"
                        />
                        <br />
                        {errors.phone_number && (
                          <small
                            style={{ fontSize: 10 }}
                            className=" text-danger float-start"
                          >
                            {errors.phone_number}
                          </small>
                        )}
                      </div> */}

                      <div class="col-md-12">
                        <label
                          for="inputCity"
                          class="form-label  float-start"
                        ></label>
                        <br></br>
                        <input
                          type="password"
                          name="password"
                          placeholder="Password"
                          value={values.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          class="form-control-lg w-100 border border-dark border-3 border-end-0 border-start-0 border-top-0 "
                          id="inputCity"
                        />
                        <br />
                        {errors.password && (
                          <small
                            style={{ fontSize: 10 }}
                            className="text-danger float-start"
                          >
                            {errors.password}
                          </small>
                        )}
                        
                      </div>

                      <div class="col-md-12">
                        <label
                          for="inputCity"
                          class="form-label  float-start"
                        ></label>
                        <br></br>
                        <input
                          type="password"
                          name="confirmpassword"
                          placeholder="Confirm Password"
                          value={values.confirmpassword}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          class="form-control-lg w-100  border border-dark border-3 border-end-0 border-start-0 border-top-0"
                          id="inputCity"
                        />
                        <br />
                        {errors.confirmpassword && (
                          <small
                            style={{ fontSize: 10 }}
                            className="text-danger float-start"
                          >
                            {errors.confirmpassword}
                          </small>
                        )}
                       
                      </div>

                      <div class="col-12">
                        <button
                          disabled={isSubmitting}
                          type="submit"
                          class="btn btn-lg btn-dark font-monospace"
                        >
                          {isSubmitting ? (
                            <>
                              
                                <span className="ms-3"> submitting </span>
                            
                            </>
                          ) : (
                            "signup"
                          )}
                        </button>
                      </div>

                      <p class="text-center">
                        have an account? login{" "}
                        <a href="/login"> here</a>
                      </p>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
            {/* //  end  */}
          </div>
          <div className="col-sm-12 col-md-12 col-lg-4"></div>
        </div>
      </div>
    </>
  );
}

export default RegisterForm;
