import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import LoginForm from "./Pages/LoginForm";
import RegisterForm from "./Pages/Signup";
import BookingForm from "./Pages/BookingForm";
import ForgotPasswordForm from "./Pages/Forgotpassword";
import Reservations from "./Pages/Reservations";
import UserContextProvider, { UserContext } from "./Context/Authcontext";
import { useContext } from "react";
import ProtectedRoute from "./Components/Utils/ProtectedRoute";
import AdminHome from "./Pages/Admin";
import Settings from "./Pages/Settings";
import Profile from "./Pages/Profile";
import Checkout from "./Pages/Checkout";
import ContactForm from "./Pages/ContactForm";
import VerifyPaystackPayment from "./Components/VerifyPay";
import VerifyPay from "./Components/VerifyPay";
import ResetPasswordComponent from "./Pages/Resetpassword.js";
import ReservationInfo from "./Pages/Bookinginfo.js";
import Gallery from "./Pages/Gallery.js";

function App() {
  const user = useContext(UserContext);

  return (
    <UserContextProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/signup" element={<RegisterForm />} />
        <Route path="/booking" element={<BookingForm />} />
        <Route path="/forgot-password" element={<ForgotPasswordForm />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/payment/verify" element={<VerifyPay />} />
        <Route path="/account/resetpassword" element={<ResetPasswordComponent/>} />
        <Route path="/gallery" element={<Gallery/>} />
        
        {/* <Route element={<ProtectedRoute isAllowed={user} />}> */}
        <Route path="/reservation/:bookingId" element={<ReservationInfo/>} />

        <Route path="/reservations" element={<Reservations />} />
        <Route path="/reservations" element={<Profile />} />
        <Route path="/reservations" element={<Settings />} />
        <Route path="/cart" element={<Checkout />} />

        {/* </Route> */}
        <Route path="/admin" element={<AdminHome />} />
      </Routes>
    </UserContextProvider>
  );
}


export default App;
