import LoginForm from "../../Pages/LoginForm"


export const constants = {
    // clientURL : "http://localhost:3000",
    // backendUrl: "http://localhost:5000"

    clientURL : "https://aludyshomes.com",
    // backendUrl: "https://aludyshomes.com/api"
    backendUrl: "https://api.aludyshomes.com"

    // backendUrl: "http://api.reliancepropertiesrealtor.com"

}


