import * as yup from "yup"

const PasswordRules = /^(?=.*\d)(?=.[a-z])(?=.[A-Z]).{5,}$/;
// min of 5 character, ! upper case letter, 1 lowe case letter, 1 numeric digit.

export const signupSchema = yup.object().shape({
    fullname:yup.string().required("please enter your fullname"),
    email: yup.string().email("Please enter a valid email").required(),
    // phone_number: yup.number().positive().min(8,"token must be 6 digits").integer().required("Enter Phone Number"),
    password: yup.string().min(1,"must be at least 3 characters").required("password required"),
    confirmpassword: yup.string().oneOf([yup.ref('password'), null], "password not match").required("password required")

})

export const loginSchema = yup.object().shape({
    email: yup.string().email("Please enter a valid email").required(),
    password: yup.string().required("password required"),
})



export const BookingFormSchema = yup.object().shape({
    fullname:yup.string().required("please enter your fullname").required(),
    email: yup.string().email("Please enter a valid email").required(),
    phonenumber: yup.number().positive().min(8,"token must be 6 digits").integer(),
    checkIn: yup.string().required("select a date"),
    checkOut: yup.string().required("select a date"),
    adults: yup.number().positive().integer(),
    children: yup.number().positive().integer(),
    
})


export const emailVerifySchema = yup.object().shape({
   verify_token: yup.number().positive().min(3,"token must be 6 digits").integer().required("Token is required"),
})
export const phoneVerifySchema = yup.object().shape({
    // country_code: yup.string().oneOf(['+234','+233', '+44', '+1']).required("Required"),
    country_code: yup.string().required("Required"),
    phone_number: yup.number().positive().min(8,"token must be 6 digits").integer().required("Enter Phone Number"),
 })

 export const phoneVerifyOTPSchema = yup.object().shape({
    phone_number_otp: yup.number().positive().min(8,"token must be 6 digits").integer().required("Enter Phone Number"),
 })



export const forgotPasswordSchema = yup.object().shape({
    email: yup.string().email("Please enter a valid email").required("Field must not be empty"),
  
 })

 export const ResetPasswordSchema = yup.object().shape({
    password: yup.string().min(1,"must be at least 3 characters").required("password required"),
    confirmpassword: yup.string().oneOf([yup.ref('password'), null], "password not match").required("password required")

})

export const ChangePasswordSchema = yup.object().shape({
    oldpassword: yup.string().min(1,"must be at least 3 characters").required("password required"),
    newpassword: yup.string().min(1,"must be at least 3 characters").required("password required"),
    confirmpassword: yup.string().oneOf([yup.ref('newpassword'), null], "password not match").required("password required")

})

export const ChangeUsernameSchema = yup.object().shape({
    username: yup.string().min(1,"must be at least 3 characters").required("Username required"),
    password: yup.string().min(1,"must be at least 3 characters").required("Password required"),

})

export const ChangeFullnameSchema = yup.object().shape({
    fullname: yup.string().min(1,"must be at least 3 characters").required("Type your required"),
    password: yup.string().min(1,"must be at least 3 characters").required("Password required"),

})

export const createPostSchema = yup.object().shape({
 
    content : yup.string().min(10,"not less than 10 words").max(256,"has exceeded word limit")
    .required("Enter Content to Post"),
    //  bold: yup.boolean(),
    //  isItalic : yup.boolean()
})




export const ContactFormSchema = yup.object().shape({
    fullname:yup.string().required(),
    subject: yup.string().required(),
    email: yup.string().email().required(),
    description: yup.string().required(),

})



// export const basicSchema = yup.object().shape({
//     email: yup.string().email("Please enter a valid email").required("Required"),
//     age: yup.number().positive().integer().required("Required"),
//     password: yup.string().min(5).required("Password Required"),
//     // .matches(PasswordRules,{ message: "Please create a stronger Password" }).required("Password Required"),
//     confirmPassword: yup.string().oneOf([yup.ref('password'), null], "password must match").required("Password required")
// })

// export const advacedSchema = yup.object().shape({
//     username :yup.string().min(5,"Username must be at least 3 characters").required("Required"),
//     jobType: yup.string().oneOf(['designer','developer', 'skitmaker']).required("Required"),
//     // .matches(PasswordRules,{ message: "Please create a stronger Password" }).required("Password Required"),
//     acceptedTos: yup.boolean().oneOf([true], 'Please accept the terms of service'),
//     files: yup.mixed().required("Required")
//   })