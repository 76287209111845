import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { loginSchema } from "../Components/FormSchema";
import ApiServices from "../Services/ApiServices";
import { displayAlert } from "../Components/Utils/Appfunctions";
import "../Components/styles.css";
import { constants } from "../Components/Utils/Constants";
import Navbar from "../Components/Navbar";


function LoginForm() {
  const initialValues = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();

  const [alertmessage, setAlertmessage] = useState(false);
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);

  const onSubmit = async (values, actions) => {
    
    try {
      if (!values) return;
      console.log("submitted", values);
      const response = await ApiServices.postRequestNoAuth("/controllers/login.php", values);
      // const response = await ApiServices.postRequestNoAuth("http://localhost:5000/controllers/login.php", values);
       
      console.log("response=", response);
      if (response) {
        const { success, message, userInfo } = response;
        setAlertmessage(true);
        
        if (success) {
          setMessage(message);
          setIsError(false);
          setTimeout(() => setAlertmessage(false), 2000);
          var str = ApiServices.setCookie("user", userInfo, 7);
          // Optionally, redirect to another page:
          window.location.replace(`${constants.clientURL}`);
        } else {
          setMessage(message);
          setIsError(true);
          setTimeout(() => setAlertmessage(false), 3000);
          actions.resetForm();
        }
      } else {
        setMessage("Unexpected error occurred.");
        setIsError(true);
        setTimeout(() => setAlertmessage(false), 3000);
        actions.resetForm();
      }
    } catch (error) {
      console.error("Login error:", error);
      setMessage("Network error occurred.");
      setIsError(true);
      setTimeout(() => setAlertmessage(false), 3000);
      actions.resetForm();
    }
  };

  return (
    <>
    <Navbar/>
      <div className="container-fluid" style={{backgroundColor:"black"}}>
        {displayAlert({ alertmessage, isError, message })}
        <br />
        <br />
        <div className="row">
          <div className="col-lg-4"></div>
          <div className="col-lg-4 vh-100">
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
            <div className="p-2 bg-light shadow mt-5  ">
              <div className="m-3">
                <Formik
                  initialValues={initialValues}
                  validationSchema={loginSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    values,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit} className="row g-3 text-center mt-5">
                      <div className="text-center">
                      {/* <a class="navbar-brand" href="/">
             <img
              src="aludyslogo.png"
              alt="Logo"
              width="150"
              height="60"
              class="d-inline-block align-text-top"
            />
              </a> */}
                     <h5 className="fs-3 font-monospace mt-3"> Login to your account </h5>

                      </div>
                      <div className="col-md-12">
                        <label htmlFor="inputCity" className="form-label float-start"></label>
                        <br />
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={values.email}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          className="form-control-lg w-100 border border-dark border-3 border-end-0 border-start-0 border-top-0"
                          id="inputCity"
                        />
                        <br />
                        {errors.email && (
                          <small style={{ fontSize: 10 }} className="text-danger float-start">
                            {errors.email}
                          </small>
                        )}
                      </div>
                      <div className="col-md-12">
                        <label htmlFor="inputCity" className="form-label float-start"></label>
                        <br />
                        <input
                          type="password"
                          name="password"
                          placeholder="*********************"
                          value={values.password}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          className="form-control-lg w-100 border border-dark border-3 border-end-0 border-start-0 border-top-0"
                          id="inputCity"
                        />
                        <br />
                        {errors.password && (
                          <small style={{ fontSize: 10 }} className="text-danger float-start">
                            {errors.password}
                          </small>
                        )}
                        <a href="/forgot-password" className="text-dark fw-bold text-decoration-none float-end">
                          forgot password
                        </a>
                      </div>
                      <div className="col-12">
                        <button
                          disabled={isSubmitting}
                          type="submit"
                          className="btn btn-lg btn-dark font-monospace"
                        >
                          {isSubmitting ? (
                            <>
                                <span className="ms-3"> Submitting </span>
                            </>
                          ) : (
                            "login"
                          )}
                        </button>
                      </div>
                      <p className="text-center">
                        Dont have? create a free account <a href="/signup"> here</a>
                      </p>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <div className="col-lg-4"></div>
        </div>
      </div>
    </>
  );
}

export default LoginForm;
