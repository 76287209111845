
function DashSideBar() {
    return ( 
        <div id='' className="d-block  mt-4 p-2 bg-white vh-100">
        <a className ='text-decoration-none text-black' href="/reservations"><span className="m-2 fs-5">Reservations</span> </a> 
       <br />
        <a className="text-decoration-none text-black" href="/profile"><span className="m-2 fs-5">Profile </span></a> 
       <br />
        <a className ='text-decoration-none text-black' href='/settings'>  <span className="m-2 fs-5">Settings </span></a>
    </div>
     );
}

export default DashSideBar;