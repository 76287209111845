import { useContext, useEffect } from "react";
import Navbar from "../Components/Navbar";
import { UserContext } from "../Context/Authcontext";
import { Navigate } from "react-router-dom";
import DashSideBar from "../Components/DashSideBar";
import ApiServices from "../Services/ApiServices";
import { constants } from "../Components/Utils/Constants";

function Profile() {
  const user = useContext(UserContext);
 
  const LogOut = () => {
    ApiServices.handleLogout();
    window.location.replace(`${constants.clientURL}`);
  };


  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      <Navbar />
      <div className="container-fluid" >
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-8 vh-100">
            <br />
            <br />  <br />
            <br />
            <div className=" bg-light shadow mt-5 rounded-4">
              <div className="m-3">
              <div className="" >
                  <br/>
                 <div className="text-dark bg-light mt-4">
                 
                 
                <h3 className="mt-2 fw-bold fs-1 m-1">Profile</h3>
                  
                <div class=" mt-4 table-responsive  p-2 ">

                <table class="table table-borderless">
                      <thead>
                      </thead>
                      <tbody>

                        <tr>
                          <th scope="row"></th>
                          <td>
                            <span className="fw-bold fs-5">Full Name </span>                
                          </td>
                          <td></td>
                          <td>       
                            <span className="ms-3 fs-6 ">{user.fullname}</span>
                          </td>
                        </tr>

                        <tr>
                          <th scope="row"></th>
                          <td>
                            <span className="fw-bold fs-5"> Email </span>                
                          </td>
                          <td></td>
                          <td>       
                            <span className="ms-3 fs-6 ">{user.email}</span>
                          </td>
                        </tr>

                        <tr>
                          <th scope="row"></th>
                          <td>
                            <span className="fw-bold fs-5">Phone Number</span>                
                          </td>
                          <td></td>
                          <td>       
                            <span className="ms-3 fs-6 ">{user.phonenumber ? user.phonenumber : "Not Provided"}</span>
                          </td>
                        </tr>
                      </tbody>
                
                    </table>
                <br/>
                {/* <br/>
                <br/> */}
                <h6 className="mt-5 text-danger fw-bold text-decoration-underline">
                   <button className="bg-white border-0 text-danger fw-bold text-decoration-underline" onClick={LogOut}> Logout</button>
             
              </h6>
               
              </div>
             
              <br/>
              <br/><br/><br/><br/><br/><br/><br/><br/>
              <br/><br/><br/><br/>
              <br/><br/><br/><br/><br/><br/>
            </div>
        </div> 

      
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div>
      </div>
    </>
  );
}

export default Profile;
